import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const AuthUser = {
  authenticate: false,
  username: "",
  company: "",
  email: "",
  teams: null,
  currTeam: null,
  fahrenheit: true,
  yellow: null,
  lightRed: null,
  darkRed: null,
  filter: "",
  ascend: "",
  error: "",
  verifyError: "",
  emailNoti: null,
  emailNotiDays: null,
  emailList: null,
};

export const verify = createAsyncThunk(
  "auth/verify",
  async (_, { rejectWithValue }) => {
    try {
      let response = await axios.get("/api/users/trap", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("GrainGuardianToken"),
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const verifyAcc = createAsyncThunk("auth/verifyAcc", async (msg) => {
  let body = { user: msg };
  let response = await axios.post("/api/users/verify", body);
  return response.data;
})

export const login = createAsyncThunk(
  "auth/login",
  async (authInfo, { rejectWithValue }) => {
    let body = { user: authInfo };
    try {
      let response = await axios.post("/api/users/login", body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async (_) => {
  console.log("logout");
  return true;
});

export const register = createAsyncThunk(
  "auth/register",
  async (authInfo, { rejectWithValue }) => {
    let body = { user: authInfo };
    try {
      let response = await axios.post("/api/users/", body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const resend = createAsyncThunk("auth/resend", async (msg) => {
  let body = { user: msg };
  let response = await axios.post("/api/users/resend", body);
  return response.data;
});

// Fetch User List
export const fetchUserLst = createAsyncThunk("auth/userLst", async () => {
  let response = await axios.get("/api/users/", {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("GrainGuardianToken"),
    },
  });
  //console.log("fetchUserLst data: " + JSON.stringify(response.data));
  //console.log("Finished fetchUserLst!");
  return response.data;
});

export const updateAccount = createAsyncThunk("auth/update", async (payload) => {
    let body = {
      user: {
        company: payload.company,
      },
    };
    console.log(body);
    let response = await axios.put("/api/users/update", body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("GrainGuardianToken"),
      },
    });
    console.log(response.data);
    return response.data;
  }
);

export const changePassword = createAsyncThunk(
  "auth/updatePassword",
  async (authInfo, { rejectWithValue }) => {
    let body = { user: authInfo };
    try {
      let response = await axios.put("/api/users/password", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("GrainGuardianToken"),
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//Forgot Password, Send Verification Code
export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (email, { rejectWithValue }) => {
    let body = { user: email };
    try {
      let response = await axios.put("api/users/password/forgot", body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//Forgot Password, Accept Verification Code
export const confirmForgotPassword = createAsyncThunk(
  "auth/confirmPassword",
  async (authInfo, { rejectWithValue }) => {
    let body = { user: authInfo };
    try {
      let response = await axios.post("api/users/password/forgot", body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// Toggle fahrenheit and celcius
export const toggleTempUnit = createAsyncThunk(
  "auth/toggleTempUnit",
  async ({ rejectWithValue }) => {
    let body = { };
    try {
      let response = await axios.put("api/users/fahrenheit", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("GrainGuardianToken"),
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const changeFilterOptions = createAsyncThunk(
  "auth/changeFilterOptions",
  async (msg, { rejectWithValue }) => {
    let body = msg;
    try {
      let response = await axios.put("api/users/changeFilterOptions", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("GrainGuardianToken"),
        },
      });
      //console.log("changeFilterOptions just finished!")
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const handleFavoritesList = createAsyncThunk(
  "auth/handleFavoritesList",
  async (msg) => {
    let body = msg;
    let response = await axios.put("/api/teams/handleFavorites", body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("GrainGuardianToken"),
      },
    });
    return response.data;
  }
);

export const changeInsectCountThreshold = createAsyncThunk(
  "auth/changeInsectCountThreshold",
  async (msg, { rejectWithValue }) => {
    let body = msg;
    const token = localStorage.getItem("GrainGuardianToken");
    try {
      let response = await axios.put("api/users/changeIndicators", body, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      console.log("LOG: Change Insect Count Threshold Ran");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//Toggle email noti
export const toggleEmailNoti = createAsyncThunk(
  "auth/toggleEmailNoti",
  async ({ rejectWithValue }) => {
    let body = {};
    const token = await localStorage.getItem("GrainGuardianToken");
    try {
      let response = await axios.put("api/notification", body, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      console.log("LOG: Email Notification Toggle Ran");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//Toggle email day
export const toggleEmailNotiDay = createAsyncThunk(
  "auth/toggleEmailNotiDays",
  async (msg, { rejectWithValue }) => {
    let body = msg;
    const token = await localStorage.getItem("GrainGuardianToken");
    try {
      let response = await axios.put("api/notification/days", body, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      console.log("LOG: Email Notification Day Toggle Ran");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const addEmail = createAsyncThunk("auth/addEmail", async (msg) => {
  let body = msg;
  const token = await localStorage.getItem("GrainGuardianToken");
  let response = await axios.put("/api/notification/addEmail", body, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  console.log("LOG: Add Email to Account Ran");
  return response.data;
});

export const removeEmail = createAsyncThunk("auth/removeEmail", async (msg) => {
  let body = msg;
  const token = await localStorage.getItem("GrainGuardianToken");
  let response = await axios.put("/api/notification/removeEmail", body, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  console.log("LOG: Remove Email to Account Ran");
  return response.data;
});

export const authSlice = createSlice({
  name: "auth",
  initialState: AuthUser,
  reducers: {
    failAuthenticate: (state, action) => {
      state.authenticate = false;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: {
    // Fulfilled
    [verify.fulfilled]: (state, action) => {
      state.authenticate = true;
      //localStorage.setItem("GrainGuardianToken", action.payload.user.token);
      state.username = action.payload.user.username;
      state.error = "";
      console.log("LOG verify ran");
    },
    [verifyAcc.fulfilled]: (state, action) => {},
    [login.fulfilled]: (state, action) => {
      state.authenticate = true;
      localStorage.setItem("GrainGuardianToken", action.payload.user.token);
      state.username = action.payload.user.username;
      state.error = "";
    },
    [logout.fulfilled]: (state, action) => {
      state.authenticate = false;
      localStorage.setItem("GrainGuardianToken", "");
      state.username = "";
      state.currTeam = null;
      state.teams = null;
      state.error = "";
    },
    [register.fulfilled]: (state, action) => {
      //state.authenticate = true;
      //localStorage.setItem("GrainGuardianToken", action.payload.user.token);
      state.username = action.payload.user.username;
      state.error = "";
    },
    [resend.fulfilled]: (state, action) => {
      console.log("resend succeed!");
    },
    [fetchUserLst.fulfilled]: (state, action) => {
      state.authenticate = true;
      state.username = action.payload.userInfo.username;
      state.company = action.payload.userInfo.company;
      state.email = action.payload.userInfo.email;
      state.fahrenheit = action.payload.userInfo.fahrenheit;
      state.yellow = action.payload.userInfo.yellow;
      state.lightRed = action.payload.userInfo.lightRed;
      state.darkRed = action.payload.userInfo.darkRed;
      state.filter = action.payload.userInfo.filter;
      state.ascend = action.payload.userInfo.ascend;
      state.teams = action.payload.userInfo.teams;
      state.emailNoti = action.payload.userInfo.notification;
      state.emailNotiDays = action.payload.userInfo.emailNotiDays;
      state.emailList = action.payload.userInfo.emailList;
      if (state.currTeam === null) {
        state.currTeam = action.payload.userInfo.teams[0];
      }
      state.error = "";
    },
    [updateAccount.fulfilled]: (state, action) => {
      state.company = action.payload.company;
    },
    [changePassword.fulfilled]: (state, action) => {},
    [forgotPassword.fulfilled]: (state, action) => {},
    [confirmForgotPassword.fulfilled]: (state, action) => {},
    [toggleTempUnit.fulfilled]: (state, action) => {},
    [changeFilterOptions.fulfilled]: (state, action) => {
      state.filter = action.payload.filterOptions.filter;
      state.ascend = action.payload.filterOptions.ascend;
    },
    [handleFavoritesList.fulfilled]: (state, action) => {},
    [changeInsectCountThreshold.fulfilled]: (state, action) => {
      state.yellow = action.payload.yellow;
      state.lightRed = action.payload.lightRed;
      state.darkRed = action.payload.darkRed;
    },
    [toggleEmailNoti.fulfilled]: (state, action) => {},
    [toggleEmailNotiDay.fulfilled]: (state, action) => {},
    [addEmail.fulfilled]: (state, action) => {},
    [removeEmail.fulfilled]: (state, action) => {},

    // Rejected
    [verify.rejected]: (state, action) => {
      //state.authenticate = false;
      //localStorage.removeItem("GrainGuardianToken");
      //state.error = "";
      console.log("in verify rejected", action);
    },
    [verifyAcc.rejected]: (state, action) => {
      state.authenticate = false;
      state.verifyError = "Invalid verification! Please check your email and code again";
    },
    [resend.rejected]: (state, action) => {
      state.error = "Failed to send code!";
    },
    [login.rejected]: (state, action) => {
      state.authenticate = false;
      // state.error = action.payload.error.message;
      state.error = "wrong username or password";
    },
    [register.rejected]: (state, action) => {
      state.authenticate = false;
      state.error = action.payload.error.message;
      //state.error = "username taken";
    },
  },
});

export const { setError, setVerifyError } = authSlice.actions;
export default authSlice.reducer;
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Redirect,
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import "./check-email.sass";

export default function CheckEmail() {

    const navigate = useHistory();

    const location = useLocation();
    const email = location.state.email;
    console.log(email);

    const goNext = () => {
        navigate.push("/createNewPassword", { email: email });
    }

    return (
        <div className="container">
            <div className="email-container">
                <div>
                    <h1 className="email-title">Check your Email</h1>
                    <p>We have sent a verification code to your email. You will receive it shortly.</p>
                    <button className="email-btn" onClick={() => goNext()}>Received Verification Code</button>
                </div>
                <div>
                    <Link to="/forgotPassword">Resend Verification Code</Link>
                </div>
            </div>
        </div>
    );
};
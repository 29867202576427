import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resend, setError } from "redux/slices/authSlice";
import { useHistory } from "react-router-dom";
import "./continue-process.sass";
import validator from "validator";

export default function ContinueProcess({ goBack }) {

    const dispatch = useDispatch();

    const navigate = useHistory();

    const Back = () => {
        goBack();
        navigate.push("/register");
    };

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [fakeSubmitting, setFakeSubmitting] = useState(false);

    // email validator
    const [validEmail, setValidEmail] = useState(true);
    useEffect(() => {
        if (email) {
        if (validator.isEmail(email) === true) {
            setValidEmail(true);
        } else {
            setValidEmail(false);
        }
        } else {
        setValidEmail(true);
        }
    }, [email]);

    // password format validation
    const [disPlayPassError, setDisplayPassError] = useState(false);
    useEffect(() => {
        if (password) {
        let reg = /.{8,}$/;
        if (reg.test(password) === false) {
            setDisplayPassError(true);
        } else {
            setDisplayPassError(false);
        }
        } else {
        setDisplayPassError(false);
        }
    }, [password]);

    const handleDisruptedVerify = async () => {
        if(email && password && !disPlayPassError && validEmail) {
            setFakeSubmitting(false);
            let data = { email: email };
            dispatch(resend(data))
            .then(() => {
                navigate.push("/verification", { email: email, password: password });
              })
              .catch(() => {
                console.log("Error, don't navigate to next screen");
              });
        } else {
            setFakeSubmitting(true);
        }
        
    };

    return (
        <div className="continue-main-container">
            <div className="continue-top-form">
                <button className="continue-back-btn" onClick={() => Back()}>Back</button>
            </div>
            <div className="continue-bottom-form">
                <h1 className="continue-title">Continue Verification Process</h1>
                <p className="continue-txt">Re-enter your email address and password associated with your account you registered. You will receive a code in your email which you will need to finish registering your account.</p>
                <div className="continue-form-container">
                    <label className="continue-form-title">Email:</label>
                    <input
                        type="email"
                        id="email"
                        className="continue-input"
                        placeholder="Enter your email address"
                        name="username"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {!email && fakeSubmitting && <label className="continue-error">Email cannot be blank</label>}
                    {!validEmail && <label className="continue-error">Invalid email</label>}
                </div>
                <div className="continue-form-container">
                    <label className="continue-form-title">Password:</label>
                    <input
                        type="password"
                        id="password"
                        className="continue-input"
                        placeholder="Enter your password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {!password && fakeSubmitting && <label className="continue-error">Password cannot be blank</label>}
                    {disPlayPassError && <label className="continue-error">Password must be at least 8 characters</label>}
                </div>
            </div>
            <div className="continue-btn-container">
                <button className="continue-send-btn" onClick={() => handleDisruptedVerify()}>Send Code</button>
            </div>
        </div>
    );
};
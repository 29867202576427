import React from "react";
import "./drop-down.sass";

export default function DropDown({ setToggle }) {

  return (
    <form>
      <select 
        onChange={(e) => {
          console.log(e.target.value);
          setToggle(e.target.value);
        }}
        style={{"font-size": "20px" }}
      >
        <option value='temperature'>Temperature</option>
        <option value='humidity'>Relative Humidity</option>
        <option value='particleCount'>Insect Count</option>
      </select>
    </form>
  
  );
};
import React from "react";
import { login } from "redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import "./verify-success.sass";

export default function VerifySuccess({ goBack }) {

    const dispatch = useDispatch();

    const navigate = useHistory();

    const location = useLocation();
    const email = location.state.email;
    const password = location.state.password;
    console.log(email);
    console.log(password);

    const continueLogin = async () => {

        let data = {
            username: email,
            password: password,
        };

        dispatch(login(data));
        goBack();
        navigate.push("/");
    };

    return (
        <div className="verify-success-main-container">
            <div className="verify-success-container">
                <h1 className="verify-success-title">Registration successful!</h1>
                <p className="verify-success-text">Congratulations, your account has been successfully created and registered. You will now be able to login and start pairing your SmartProbes.</p>
                <div className="verify-success-btn-container">
                    <button className="verify-success-btn" onClick={() => continueLogin()}>Continue</button>
                </div>
            </div>
        </div>
    )
}
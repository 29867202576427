import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {removeTrap, deleteCurrentData, fetchScrollInfo, fetchDataLst} from "redux/slices/dataSlice"; 

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import { Dialog, DialogContentText, DialogTitle } from "@mui/material";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import loadingIcon from 'assets/loading.gif'

import './trap-functions.sass';

export default function RemoveProbe({setOpenFuncs, handleClosingFunction, pageNum}){
    const dispatch = useDispatch();
    const { trapId, dataLst, trapName } = useSelector((state) => state.data);
    const { filter, ascend, currTeam } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
 
    const submitRemoveProbe = async () => {
        setLoading(true);
        let msg = {
            trapId: trapId,
            teamName: currTeam,
        };
        await dispatch(deleteCurrentData(msg));
        let response = await dispatch(removeTrap(msg));
        let payload = {
            msg: {
              filter: filter,
              ascend: ascend,
              teamName: currTeam,
            },
            num: pageNum,
          };
        await dispatch(fetchScrollInfo(payload));
        dispatch(fetchDataLst(trapId));
        setTimeout(() => {
            setLoading(false);
            console.log("response payload status: " + response.payload.status);
            if (response.payload.status === "SUCCESS") {
                alert("Success, SmartProbe has been successfully removed from your account.");
            } else {
                alert("Failed, something went wrong with removing your SmartProbe. Try Again.");
            }
          }, 3000);

        setOpenFuncs(false);
    }

    return(
        loading ? (
            <DialogContent >
                <img src={loadingIcon} alt="Loading..." className = "loading-icon"></img>
                <DialogContentText textAlign = "center"> <b>SmartProbe is being removed. <br></br> 
                This may take a minute...</b></DialogContentText> 

            </DialogContent>
        ) : (
        <div>
            <DialogContent>
                <Button variant = "outlined" className = "custom-button" onClick = {() => handleClosingFunction()}>Back</Button>
                <DialogTitle textAlign = "center"> <b>Remove SmartProbe</b> </DialogTitle>

                <DialogContentText> 
                    Are you sure you want to remove 
                    {trapName ? (
                        <b> Smartprobe {trapName} from your account? </b>
                    ) : (
                        <b> SmartProbe ID: {trapId} from your account? </b>
                    )} 
                    You cannot undo this action.
                    
                    <Alert severity= "error" className= "alert">
                        <AlertTitle> <b> Warning </b> </AlertTitle>
                        Please note that if you remove this SmartProbe from your
                        account, all current data will be lost. If you want to save the
                        data before removing your device, go to Store Data & Clean SmartProbe.
                    </Alert>
                    <Button variant = "outlined" className = "custom-button" onClick = {() => submitRemoveProbe()}>Remove SmartProbe</Button>
                </DialogContentText>

            </DialogContent>
        </div>
        )
    );

}
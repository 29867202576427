import { configureStore } from "@reduxjs/toolkit";
import allReducer from "./slices";
import axios from "axios";
// **************************** CHANGE PATH****************************************************
//InsectDetection url : http://54.193.107.199:3000
//GrainGuardian url : http://54.193.219.118:3000
//InsectDetection url V.2 : http://13.56.238.65:3000
// local: http://localhost:3000/
//axios.defaults.baseURL = "http://13.56.238.65:3000";
//axios.defaults.baseURL = "http://localhost:3000/";
axios.defaults.baseURL = "https://api.aivisionfood.com";
export default configureStore({
  reducer: allReducer,
});

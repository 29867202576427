import React from "react";
import "./page-bar.sass";

import Btn from "components/btn/Btn";
import { Slider } from "@mui/material";

export default function PageBar({ content, totalPage, page, setPage, handleDisable }) {
  const handlePagePrev = (e) => {
    e.preventDefault();
    let _page = page - 1;
    if (_page < 1) {
      return;
    }
    setPage(_page);
  };

  const handlePageNext = (e) => {
    e.preventDefault();
    e.preventDefault();
    let _page = page + 1;
    if (_page > totalPage) {
      return;
    }
    setPage(_page);
  };

  return (
    <div className="page-bar">
      <div className="page-bar-btn" onClick={handleDisable ? null : handlePagePrev}>
        <Btn content="Prev" bgColor="blue" CustomFontSize="0.9rem" handleDisabled={handleDisable} />
      </div>

      <div className="page-bar-slider">
        <div className="page-bar-content">{content}</div>
        {totalPage > 1 && (
          <Slider
            value={page}
            size="small"
            aria-label="Small"
            valueLabelDisplay="auto"
            min={1}
            max={totalPage}
            onChange={(e) => setPage(e.target.value)}
          />
        )}
      </div>

      <div className="page-bar-btn" onClick={handleDisable ? null : handlePageNext}>
        <Btn content="Next" bgColor="blue" CustomFontSize="0.9rem" handleDisabled={handleDisable} />
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router, 
  useHistory,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import "./forgot-password.sass";

export default function DeleteAccount({ goBack }) {

  const dispatch = useDispatch();
  const navigate = useHistory();

  const Back = () => {
    goBack();
    navigate.push("/");
  }
  
  return (
    <div className="main-container">
        <div className="top-form">
            <button className="back-btn" onClick={() => Back()}>Back</button>
        </div>
        <div className="bottom-form">
          <h1 className="title">Delete Account</h1>
          <p className="txt">For account deletion, please contact support@aivisionfood.com</p>
          
        </div>
    </div>
  );
}
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { cleanTrap, fetchDataLst, fetchScrollInfo, fetchTrapInfo, updateTrap } from "redux/slices/dataSlice";

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
// Below are the 4 trap functions
import CleanProbe from "components/modal/CleanProbe";
import DeleteCurrentData from "components/modal/DeleteCurrentData";
import DeleteAllData from "components/modal/DeleteAllData";
import RemoveProbe from "components/modal/RemoveProbe";
// Buttons for function icons
import storeData from "assets/floppy_disk.png";
import deleteAccount from "assets/deleteAccount2.png";
import removeSmartProbe from "assets/removeSmartProbe2.png";

import './trap-functions.sass';

export default function TrapFunctions({openFuncs, setOpenFuncs, pageNum}){
    const { trapId, dataLst } = useSelector((state) => state.data);
    const [isAFuncOpen, setIsAFuncOpen] = useState(false);
    const [openClean, setOpenClean] = useState(false);
    const [openDeleteCurrent, setOpenDeleteCurrent] = useState(false);
    const [openDeleteAll, setOpenDeleteAll] = useState(false);
    const [openRemoveProbe, setOpenRemoveProbe] = useState(false);

    const handleClose = () => {
        setOpenFuncs(false);
    };
    const handleClean = () => {
        setOpenClean(true);
        setIsAFuncOpen(true);
    };
    const handleDeleteCurrent = () => {
        setOpenDeleteCurrent(true);
        setIsAFuncOpen(true);
    }
    const handleDeleteAll = () => {
        setOpenDeleteAll(true);
        setIsAFuncOpen(true);
    }
    const handleRemoveProbe = () => {
        setOpenRemoveProbe(true);
        setIsAFuncOpen(true);
    }
    // We pass in this function as a prop to children for "back" button
    const handleClosingFunction = () => {
        setOpenClean(false);
        setOpenDeleteCurrent(false);
        setOpenDeleteAll(false);
        setOpenRemoveProbe(false);
        setIsAFuncOpen(false);
    }
    //console.log("trapfuncscalled");
    return(
        <div>
            <Dialog open={openFuncs} onClose={handleClose} fullWidth maxWidth='xs' 
                        PaperProps={{ className: "custom-paper"}}> 

                {/* // This displays the list of trap buttons. Each {}  can only return one expression*/}
                {!isAFuncOpen && <DialogTitle textAlign= "center"> <b>SmartProbe Functions  </b></DialogTitle>}
                <div className = "functions-box">
                    {!isAFuncOpen && <Button className = "custom-button" onClick = {() => handleClean()}> 
                        <img src = {storeData} className = "function-icons" /> Store Data & Clean Smartprobe </Button>}

                    {!isAFuncOpen && <Button className = "custom-button" onClick = {() => handleDeleteCurrent()}> 
                        <img src = {deleteAccount} className = "function-icons"/> Delete All Current Data </Button>}

                    {!isAFuncOpen && <Button className = "custom-button" onClick = {() => handleDeleteAll()}> 
                        <img src = {deleteAccount} className = "function-icons"/> Delete All Stored & Current Data </Button>}
                        
                    {!isAFuncOpen && <Button className = "custom-button" onClick = {() => handleRemoveProbe()}> 
                        <img src = {removeSmartProbe} className = "function-icons"/> Remove SmartProbe </Button>}
                </div>
                
                {/*This displays the list of Dialogs that are activated when the buttons are pressed*/}
                {openClean && <CleanProbe setOpenFuncs = {setOpenFuncs} handleClosingFunction = {handleClosingFunction} pageNum = {pageNum}/>}
                {openDeleteCurrent && <DeleteCurrentData setOpenFuncs = {setOpenFuncs} handleClosingFunction = {handleClosingFunction} pageNum = {pageNum}/>}
                {openDeleteAll && <DeleteAllData setOpenFuncs = {setOpenFuncs} handleClosingFunction = {handleClosingFunction} pageNum = {pageNum}/>}
                {openRemoveProbe && <RemoveProbe setOpenFuncs = {setOpenFuncs} handleClosingFunction = {handleClosingFunction} pageNum = {pageNum}/>}

            </Dialog>   

        </div>
    );
};
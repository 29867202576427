import React from "react";
import {
    BrowserRouter as Router,
    Route,
    NavLink,
    Redirect,
    Link,
    useHistory,
    useLocation
} from "react-router-dom";
import "./check-email.sass";

export default function CreateNewPasswordSuccess({ goBack }) {

    const navigate = useHistory();

    const goToLogin = () => {
        goBack();
        navigate.push("/");
    }

    return (
        <div className="container">
            <div className="email-container">
                <div>
                    <h1>Password Reset</h1>
                    <p>Your password has been reset successfully. Now try logging in with your new password.</p>
                </div>
                <div>
                    <button className="email-btn" onClick={() => goToLogin()}>Login</button>
                </div>
            </div>
        </div>
    );
};


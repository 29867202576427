import React from "react";
import { BsStar, BsStarFill } from 'react-icons/bs';
import { useSelector, useDispatch } from "react-redux";
import { fetchScrollInfoRefresh, fetchScrollInfo } from "redux/slices/dataSlice";
import { handleFavoritesList } from "redux/slices/authSlice";

const FavoriteButton = ({ stateBtn, pageNum, btnDisable }) => {
    const dispatch = useDispatch();
    const { trapId } = useSelector((state) => state.data);
    const { filter, ascend, currTeam, teams } = useSelector(
        (state) => state.auth
    );

    const handlePress = async () => {

        let msg = {
            teamName: currTeam,
            trapId: trapId,
        };

        await dispatch(handleFavoritesList(msg));

        let payload = {
            msg: {
                filter: filter,
                ascend: ascend,
                teamName: currTeam,
            },
            num: pageNum,
        };

        if (filter === "favorite") {
            await dispatch(fetchScrollInfo(payload));
        } else {
            await dispatch(fetchScrollInfoRefresh(payload));
        }

    };

    const iconStyle = {
        pointerEvents: btnDisable ? "none" : "auto",
        cursor: "pointer",
    };

    return (
        <div type="button" onClick={() => handlePress()} style={iconStyle}>
          {stateBtn ? <BsStarFill color="gold" size={30} /> : <BsStar color="black" size={30} />}
        </div>
      );
};

export default FavoriteButton;


import React from "react";
import "./btn.sass";

export default function Btn({
  content,
  bgColor,
  CustomFontSize = "1.2rem",
  handleClick,
  handleDisabled,
}) {
  const contentBlock = (
    <div className="btn-content" style={{ fontSize: CustomFontSize }}>
      {content}
    </div>
  );

  function Main() {
    switch (bgColor) {
      case "yellow":
        return (
          <div className={handleDisabled ? "btn-yellow btnDisable" : "btn-yellow btn"} onClick={handleClick}>
            {contentBlock}
          </div>
        );
      case "green":
        return (
          <div className={handleDisabled ? "btn-green btnDisable" : "btn-green btn"} onClick={handleClick}>
            {contentBlock}
          </div>
        );
      case "blue":
        return (
          <div className={handleDisabled ? "btn-blue btnDisable" : "btn-blue btn"} onClick={handleClick}>
            {contentBlock}
          </div>
        );
      default:
        return <div></div>;
    }
  }

  return <Main />;
}


import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Redirect,
  Link,
  useHistory,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgotPassword } from "redux/slices/authSlice";
import validator from "validator";
import "./forgot-password.sass";

export default function ForgotPassword({ goBack }) {

  const dispatch = useDispatch();

  const navigate = useHistory();

  const [email, setEmail] = useState("");
  const [fakeSubmitting, setFakeSubmitting] = useState(false);

  // email validator
  const [validEmail, setValidEmail] = useState(true);
  useEffect(() => {
    if (email) {
      if (validator.isEmail(email) === true) {
        setValidEmail(true);
      } else {
        setValidEmail(false);
      }
    } else {
      setValidEmail(true);
    }
  }, [email]);

  const requestCode = async () => {
    if (email && validEmail) {
      setFakeSubmitting(false);

      let user = {
        email: email,
      };

      let response = await dispatch(forgotPassword(user));

      navigate.push("/checkEmail", { email: email });

    } else {
      setFakeSubmitting(true);
    }
  };

  const Back = () => {
    goBack();
    navigate.push("/");
  }
  
  return (
    <div className="main-container">
        <div className="top-form">
            <button className="back-btn" onClick={() => Back()}>Back</button>
        </div>
        <div className="bottom-form">
          <h1 className="title">Reset Password</h1>
          <p className="txt">Enter the email associated with your account and we'll send an email with your verification code to reset your password.</p>
          <div className="form-container">
            <label className="form-title">
              Email address
            </label>
            <input
              type="email"
              className="input"
              placeholder="Enter your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {!email && fakeSubmitting && <p className="error">Can't be blank</p>}
            {!validEmail && <p className="error">Invalid email</p>}
          </div>
          <div className="btn-container">
            <button className="send-btn" onClick={() => requestCode()}>Send Verification Code</button>
            <Link to="/skipForgotPassword">Already have verification code</Link>
          </div>
        </div>
    </div>
  );
}
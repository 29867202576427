import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {deleteCurrentData, fetchScrollInfoRefresh, fetchDataLst} from "redux/slices/dataSlice";

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import { Dialog, DialogContentText, DialogTitle } from "@mui/material";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import loadingIcon from 'assets/loading.gif'

import './trap-functions.sass';

export default function DeleteCurrentData({setOpenFuncs, handleClosingFunction, pageNum}){
    const dispatch = useDispatch();
    const { trapId, dataLst, trapName } = useSelector((state) => state.data);
    const {filter, ascend, currTeam} = useSelector((state) => state.auth);

    const [loading, setLoading] = useState(false);
    
    const submitDeleteCurrentData = async () => {
        setLoading(true);
        let msg = {
            trapId: trapId,
        }
        let response = await dispatch(deleteCurrentData(msg));
        let payload = {
            msg: {
              filter: filter,
              ascend: ascend,
              teamName: currTeam,
            },
            num: pageNum,
          };
        await dispatch(fetchScrollInfoRefresh(payload));
        dispatch(fetchDataLst(trapId));
        setTimeout(() => {
            setLoading(false);
            console.log("response payload status: " + response.payload.status);
            if (response.payload.status === "SUCCESS") {
                alert("Success, all current stored data has been successfully deleted.");
            } else {
                alert("Failed, something went wrong with deleting current stored data. Try Again.");
            }
          }, 3000);

        setOpenFuncs(false);
    }

    return(
        loading ? (
            <DialogContent >
                <img src={loadingIcon} alt="Loading..." className = "loading-icon"></img>
                <DialogContentText textAlign = "center"> <b>Data is being deleted. <br></br> 
                This may take a minute...</b></DialogContentText> 

            </DialogContent>
        ) : (
        <div>
            <DialogContent>
                <Button variant = "outlined" className = "custom-button" onClick = {() => handleClosingFunction()}>Back</Button>
                <DialogTitle textAlign = "center"> <b>Delete All Current Data</b> </DialogTitle>

                <DialogContentText> 
                    Are you sure you want to delete all current stored data from 
                    {trapName ? (
                        <b> Smartprobe {trapName}? </b>
                    ) : (
                        <b> SmartProbe ID: {trapId}? </b>
                    )} 
                    You cannot undo this action.
                <Alert severity= "error" className= "alert">
                    <AlertTitle> <b> Warning </b> </AlertTitle>
                    Please note that if you delete this data, it will not be
                    recoverable. If you want to be able to see this data in the
                    future, go to Store Data & Clean SmartProbe.
                </Alert>
                <Button variant = "outlined" className = "custom-button" onClick = {() => submitDeleteCurrentData()}>Delete data</Button>
                </DialogContentText>
            </DialogContent>
        </div>
        )
    );

}
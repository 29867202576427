import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./login-block.sass";

import { useDispatch } from "react-redux";
import { login, setError } from "redux/slices/authSlice";

export default function LoginPanel({ setOpen }) {
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const printValues = (e) => {
    e.preventDefault();

    if (!password || !username) {
      dispatch(setError("username or password empty"));
      return;
    }

    let msg = {
      username: username,
      password: password,
    };
    
    dispatch(login(msg));

    setPassword("");
  };

  document.addEventListener("keydown", function(event) {
    if (event.key === "Enter" && document.location.pathname !== "/register"){
      event.preventDefault();
      document.getElementById("signInButton").click();
    }
  });
  
  return (
    <div className="panel">
      <div className="form-field">
        <label className="form-field-label" htmlFor="username">
          Username
        </label>
        <input
          type="text"
          id="username"
          className="form-field-input"
          placeholder="Enter your username"
          name="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>

      <div className="form-field">
        <label className="form-field-label" htmlFor="password">
          Password
        </label>
        <input
          type="password"
          id="password"
          className="form-field-input"
          placeholder="Enter your password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <div className="form-field">
        <button id = "signInButton" className="form-field-button mr-20" onClick={printValues}>
          Sign In
        </button>{" "}
        <Link to="/register" className="form-field-link">
          Create an account
        </Link>
        <Link to="/forgotPassword" className="form-field-link" onClick={() => setOpen(false)}>
          Forgot password
        </Link>
        <Link to="/deleteAccount" className="form-field-link" onClick={() => setOpen(false)}>
          Delete account
        </Link>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeFilterOptions } from "redux/slices/authSlice";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import {fetchScrollInfoRefresh } from "redux/slices/dataSlice";

export default function FilterOptionsModal({ open, setOpen, handlePageNumState, setCurrentPage, pageNum, currTeam, scrollInfo, setFilterConfirmed }) {

    const dispatch = useDispatch();
    const { filter, ascend } = useSelector((state) => state.auth);

    const FilterScale = [
        { label: "ID", value: "id" },
        { label: "Name", value: "name" },
        { label: "Building", value: "building" },
        { label: "Location", value: "location" },
        { label: "Favorites", value: "favorite" },
    ];
    
    const OrderScale = [
        { label: "Descending", value: false },
        { label: "Ascending", value: true },
    ];

    const [filterOption, setFilterOption] = useState(filter);
    const [orderOption, setOrderOption] = useState(ascend);
  
    const handleFilterChange = (event) => {
      setFilterOption(event.target.value);
    };
  
    const handleOrderChange = (event) => {
      setOrderOption(event.target.value);
    };

    const applyFilterChanges = async () => {
        let body = {
          filter: filterOption,
          ascend: orderOption,
        };
        let payload = {
          msg: {
            filter: filterOption,
            ascend: orderOption,
            teamName: currTeam,
          },
          num: 20,
        };
        handlePageNumState(20);
        await dispatch(changeFilterOptions(body)); 
        //console.log("applyFilterChanges payload: ", payload);
        await dispatch(fetchScrollInfoRefresh(payload)); 
        setCurrentPage(1);  
        setFilterConfirmed(true);
        setOpen(false);
      };
  
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} maxWidth='xs'>
                <DialogTitle>Filter Options</DialogTitle>
                <DialogContent>
                    <div>
                        <TextField
                            id="outlined-select-currency"
                            select
                            margin="normal"
                            label="Filter Options"
                            value={filterOption}
                            onChange={handleFilterChange}
                            helperText="Please select filter option"
                        >
                            
                            {FilterScale.map((option) => (
                                
                                <MenuItem key={option.value} value={option.value}>
                                {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div>
                        <TextField
                            id="outlined-select-currency"
                            select
                            margin="normal"
                            label="Order Option"
                            value={orderOption}
                            onChange={handleOrderChange}
                            helperText="Please select order option"
                        >
                            {OrderScale.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()}>Cancel</Button>
                    <Button onClick={() => applyFilterChanges()}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </div>

    );
};
import React from "react";
import "./insectIndicator.sass";
import insect_dark_red from "assets/insect_dark_red.png";
import insect_green from "assets/insect_green.png";
import insect_red from "assets/insect_red.png";
import insect_yellow from "assets/insect_yellow.png";
import insect_dark from "assets/bug_solid.png";
import { useSelector, useDispatch } from "react-redux";

export default function InsectIndicator({ count }) {

    const { yellow, lightRed, darkRed } = useSelector((state) => state.auth);

    let _count = parseFloat(count);
    if (isNaN(_count)) {
        return (
            <div>
            <img
                alt="insect low"
                src={insect_dark}
                className="insect"
                >
            </img>
        </div>
        );
    }
   
    if (_count == -1) {
        return (
            <div>
            <img
                alt="insect low"
                src={insect_dark}
                className="insect"
                >
            </img>
        </div>
        );
    } else if(_count <= yellow) {
        return (
            <div>
            <img
                alt="insect medium"
                src={insect_green}
                className="insect"
                >
            </img>
        </div>
        );

    } else if(_count > yellow && _count <= lightRed) {
        return (
            <div>
            <img
                alt="insect medium"
                src={insect_yellow}
                className="insect"
                >
            </img>
        </div>
        );

    } else if(_count > lightRed && _count <= darkRed) {
        return (
            <div>
            <img
                alt="insect high"
                src={insect_red}
                className="insect"
                >
            </img>
        </div>
        );

    } else {
        return (
            <div>
            <img
                alt="insect very high"
                src={insect_dark_red}
                className="insect"
                >
            </img>
        </div>
        );

    }

};



import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "./login-block.sass";

import { useDispatch, useSelector } from "react-redux";
import { register, setError } from "redux/slices/authSlice";
import validator from "validator";

export default function RegisterPanel({ setOpen }) {

  const dispatch = useDispatch();

  const navigate = useHistory();

  const [regKey, setRegKey] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [password, setPassword] = useState("");
  const [secPassword, setSecPassword] = useState("");

  const [fakeSubmitting, setFakeSubmitting] = useState(false);
  const errMsg = useSelector((state) => state.auth.error);
  const [errorMesage, setErrorMessage] = useState("");

  const clearErrorMsg = () => {
    dispatch(setError(""));
    setErrorMessage("");
  };

  // cannot take dependency since we want useEffect run as long as errMsg is not empty.
  // since there might get same error messages
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (errMsg) {
      setErrorMessage(errMsg);
    }
  });

  // this is to clean the error message when user switch between login and register
  useEffect(() => {
    clearErrorMsg();
    //console.log("Clear Error Messages");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // registration code format - make sure only numbers
  const [keyError, setKeyError] = useState(false);
  useEffect(() => {
    if (regKey) {
      let reg = /^[0-9]+$/;
      if (reg.test(regKey) === false) {
        setKeyError(true);
      } else {
        setKeyError(false);
      }
    } else {
      setKeyError(false);
    }
  }, [regKey]);

  // password format validation
  const [disPlayPassError, setDisplayPassError] = useState(false);
  useEffect(() => {
    if (password) {
      let reg = /.{8,}$/;
      if (reg.test(password) === false) {
        setDisplayPassError(true);
      } else {
        setDisplayPassError(false);
      }
    } else {
      setDisplayPassError(false);
    }
  }, [password]);

  // email validator
  const [validEmail, setValidEmail] = useState(true);
  useEffect(() => {
    if (email) {
      if (validator.isEmail(email) === true) {
        setValidEmail(true);
      } else {
        setValidEmail(false);
      }
    } else {
      setValidEmail(true);
    }
  }, [email]);

  // Company validator
  const [validCompany, setValidCompany] = useState(false);
  useEffect(() => {
    if (company !== "" && company ) {
        setValidCompany(true);
    } else {
      setValidCompany(false);
    }
  }, [company]);

  // confirm new password matches with new password
  const [newPasswordEquals, setNewPasswordEquals] = useState(false);
  useEffect(() => {
      if (secPassword === password) {
          setNewPasswordEquals(false);
      } else {
          setNewPasswordEquals(true);
      }
  }, [secPassword, password]);

  const handleRegister = async () => {
    if(regKey && email && validEmail && password && !disPlayPassError && secPassword && !newPasswordEquals) {
      
      setFakeSubmitting(false);
      
      let data = {
        username: email,
        key: regKey,
        email: email,
        company: company,
        password: password,
      };

      dispatch(register(data))
        .unwrap()
        .then(() => {
          setOpen(false);
          navigate.push("/verification", { email: email, password: password });
        })
        .catch(() => {
          console.log("Error, don't navigate to next screen");
        });

    } else {
      setFakeSubmitting(true);
    }

  }

  document.addEventListener("keydown", function(event) {
    if (event.key === "Enter" && document.location.pathname === "/register"){
      event.preventDefault();
      document.getElementById("registerButton").click();
    }
  });

  return (
    <div className="panel">
      <div className="registration-error">
        {errorMesage && <label className="registration-error-text">{errorMesage}</label>}
      </div>
      <div className="form-field">
        <label className="form-field-label" htmlFor="regKey">
          Registration Key
        </label>
        <input
          type="text"
          id="regKey"
          className="form-field-input"
          placeholder="Enter your Registration Key"
          name="regKey"
          value={regKey}
          onChange={(e) => setRegKey(e.target.value)}
        />
        {!regKey && fakeSubmitting && <label className="form-field-error-label">Registration Key cannot be blank</label>}
        {keyError && <label className="form-field-error-label">Registration key can only contain numbers</label>}
      </div>
      <div className="form-field">
        <label className="form-field-label" htmlFor="email">
          Username
        </label>
        <input
          type="email"
          id="email"
          className="form-field-input"
          placeholder="Enter your email address"
          name="username"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {!email && fakeSubmitting && <label className="form-field-error-label">Email cannot be blank</label>}
        {!validEmail && <label className="form-field-error-label">Invalid email</label>}
      </div>
      <div className="form-field">
        <label className="form-field-label" htmlFor="company">
          Company
        </label>
        <input
          type="text"
          id="company"
          className="form-field-input"
          placeholder="Enter your company name"
          name="company"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
        />
        {!validCompany && fakeSubmitting && <label className="form-field-error-label">Company cannot be blank</label>}
      </div>
      <div className="form-field">
        <label className="form-field-label" htmlFor="password">
          Password
        </label>
        <input
          type="password"
          id="password"
          className="form-field-input"
          placeholder="Enter your password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {!password && fakeSubmitting && <label className="form-field-error-label">Password cannot be blank</label>}
        {disPlayPassError && <label className="form-field-error-label">Password must be at least 8 characters</label>}
      </div>
      <div className="form-field">
        <label className="form-field-label" htmlFor="rePassword">
          Confirm Password
        </label>
        <input
          type="password"
          id="rePassword"
          className="form-field-input"
          placeholder="Re-type your password"
          name="rePassword"
          value={secPassword}
          onChange={(e) => setSecPassword(e.target.value)}
        />
        {!secPassword && fakeSubmitting && <label className="form-field-error-label">Password cannot be blank</label>}
        {newPasswordEquals && <label className="form-field-error-label">Password does not match</label>}
      </div>

      <div className="form-field">
        <button id = "registerButton" className="form-field-button mr-20" onClick={() => handleRegister()}>
          Sign Up
        </button>{" "}
        <Link to="/" className="form-field-link">
          I'm already member
        </Link>
        <Link to="/continueProcess" onClick={() => setOpen(false)} className="form-field-link">
          Click to continue previous verification process
        </Link>
      </div>
    </div>
  );
}

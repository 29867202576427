import React from "react";
import "./batteryIndicator.sass";
import battery_full from "assets/battery_full.png";
import battery_half from "assets/battery_half.png";
import battery_quarter from "assets/battery_quarter.png";
import battery_three_quarters from "assets/battery_three_quarters.png";
import battery_empty from "assets/battery_empty.png";

export default function BatteryIndicator({ count }) {
  
    let _count = parseFloat(count);

    if (_count > 3.79) {
        return (
            <div>
            <img
                alt="battery full"
                src={battery_full}
                className="battery"
                >
            </img>
        </div>
        );
    } else if(_count > 3.6 && _count <= 3.79) {
        return (
            <div>
            <img
                alt="battery three quarters"
                src={battery_three_quarters}
                className="battery"
                >
            </img>
        </div>
        );

    } else if(_count > 3.41 && _count <= 3.6) {
        return (
            <div>
            <img
                alt="battery half"
                src={battery_half}
                className="battery"
                >
            </img>
        </div>
        );

    } else if(_count > 3.18 && _count <= 3.41) {
        return (
            <div>
            <img
                alt="battery quarter"
                src={battery_quarter}
                className="battery"
                >
            </img>
        </div>
        );

    } else {
        return (
            <div>
            <img
                alt="battery empty"
                src={battery_empty}
                className="battery"
                >
            </img>
        </div>
        );
    }
};
import React from "react";
import "./wifiIndicator.sass";
import full_wifi from "assets/full_wifi.png";
import no_wifi from "assets/no_wifi.png";
import one_bar_wifi from "assets/one_bar_wifi.png";
import three_bar_wifi from "assets/three_bar_wifi.png";
import two_bar_wifi from "assets/two_bar_wifi.png";
export default function WifiIndicator({iconvalue}) {

    let _count = parseFloat(iconvalue);
    
    if (_count === 0) {
        return (
            <div>
                <img
                    alt="no wifi"
                    src={no_wifi}
                    className="wifi"
                    >
                </img>
            </div>
        );
    } else if(_count >= -50) {
        return (
            <div>
                <img
                    alt="full wifi"
                    src={full_wifi}
                    className="wifi"
                    >
                </img>
            </div>
        );
    } else if(_count < -50 && _count >= -60) {
        return (
            <div>
                <img
                    alt="three bar wifi"
                    src={three_bar_wifi}
                    className="wifi"
                    >
                </img>
            </div>
        );

    } else if(_count < -60 && _count >= -70) {
        return (
            <div>
                <img
                    alt="two bar wifi"
                    src={two_bar_wifi}
                    className="wifi"
                    >
                </img>
            </div>
        );

    } else if(_count < -70 && _count >= -80){
        return (
            <div>
                <img
                    alt="one bar wifi"
                    src={one_bar_wifi}
                    className="wifi"
                    >
                </img>
            </div>
        );

    } else {
        return (
            <div>
                <img
                    alt="no wifi"
                    src={no_wifi}
                    className="wifi"
                    resizeMode="contain" 
                    >
                </img>
            </div>
        );
    }


}


import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserLst, changePassword } from "redux/slices/authSlice";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import SuccessModal from "./SuccessModal";
import FailModal from "./FailModal";

export default function ChangePasswordModal({ open, setOpen}) {

    const dispatch = useDispatch();

    // Holds Passwords
    const [oldPassword, setOldPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [confirmNewPassword, setConfirmNewPassword] = useState();
    const [fakeSubmitting, setFakeSubmitting] = useState(false);

    const handleOldPasswordChange = (event) => {
        setOldPassword(event.target.value);
    };

    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
    };

    const handleConfirmNewPassword = (event) => {
        setConfirmNewPassword(event.target.value);
    };

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    // password format validation
    const [disPlayPassError, setDisplayPassError] = useState(false);
    useEffect(() => {
        if (newPassword) {
        let reg = /.{8,}$/;
        if (reg.test(newPassword) === false) {
            setDisplayPassError(true);
        } else {
            setDisplayPassError(false);
        }
        } else {
            setDisplayPassError(false);
        }
    }, [newPassword]);

    // confirm new password matches with new password
    const [newPasswordEquals, setNewPasswordEquals] = useState(false);
    useEffect(() => {
        if (confirmNewPassword === newPassword) {
            setNewPasswordEquals(false);
        } else {
            setNewPasswordEquals(true);
        }
    }, [confirmNewPassword, newPassword]);

    useEffect(() => {
        dispatch(fetchUserLst());
      });
    
    const { email } = useSelector((state) => state.auth);
    
    const submitValues = async () => {
        console.log("testing");
        if (
            oldPassword &&
            newPassword &&
            confirmNewPassword &&
            !disPlayPassError &&
            !newPasswordEquals
        ) {
            setFakeSubmitting(false);

            let user = {
                email: email,
                password: oldPassword,
                newpassword: confirmNewPassword,
            };

            let response = await dispatch(changePassword(user));

            // if response is equal to SUCCESS, navigate to next screen
            if (response.payload.response !== "SUCCESS") {
                setOldPassword("");
                setNewPassword("");
                setConfirmNewPassword("");

                setOpenFail(true);

            } else {
                setOpenSuccess(true);
                setTimeout(async () => {
                    handleClose();
                }, 5000);
            }
        } else {
            setFakeSubmitting(true);
        }
    };

    const [openSuccess, setOpenSuccess] = useState(false);
    const [openFail, setOpenFail] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose}  maxWidth='xs'>
                <DialogTitle>Change Password</DialogTitle>
                <DialogContent>
                <FormControl sx={{ m: 1, width: "35ch" }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">
                        Current Password
                    </InputLabel>
                    <Input
                        id="standard-adornment-password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        }
                        value={oldPassword}
                        onChange={handleOldPasswordChange}
                    />
                    {!oldPassword && fakeSubmitting && <FormHelperText style={{color : "red"}}>Can't be blank</FormHelperText>}
                </FormControl>
                <FormControl sx={{ m: 1, width: "35ch" }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">
                        New Password
                    </InputLabel>
                    <Input
                        id="standard-adornment-password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        }
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                    />
                     {!newPassword && fakeSubmitting && <FormHelperText style={{color : "red"}}>Can't be blank</FormHelperText>}
                    {disPlayPassError &&  <FormHelperText style={{color : "red"}}>Password must be at least 8 characters</FormHelperText>}
                </FormControl>
                <FormControl sx={{ m: 1, width: "35ch" }}  variant="standard">
                    <InputLabel htmlFor={newPasswordEquals ? "standard-adornment-password" : "component-error"}>
                        Confirm New Password
                    </InputLabel>
                    <Input
                        id={newPasswordEquals ? "standard-adornment-password" : "component-error"}

                        type={showPassword ? "text" : "password"}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        }
                        value={confirmNewPassword}
                        onChange={handleConfirmNewPassword}
                    />
                     {!newPassword && fakeSubmitting && <FormHelperText style={{color : "red"}}>Can't be blank</FormHelperText>}
                    {newPasswordEquals &&  <FormHelperText style={{color : "red"}}>Password Confirmation does not match</FormHelperText>}
                </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={submitValues}>Update Password</Button>
                </DialogActions>
            </Dialog>
            {openSuccess && <SuccessModal open={openSuccess} setOpen={setOpenSuccess}/>}
            {openFail && <FailModal open={openFail} setOpen={setOpenFail}/>}
        </div>
    );
};

 
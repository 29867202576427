import React from "react";
// import "./trap-img-modal.sass";
import Dialog from "@mui/material/Dialog";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import useWindowDimensions from "components/useWindowDimensions";

import { useSelector } from "react-redux";

export default function InfoBox({ page, setPop, pop, setPage, totalPage }) {

  const { trapId, dataLst } = useSelector((state) => state.data);
  const { height, width } = useWindowDimensions();

  const handlePrev = (event) => {
    event.stopPropagation();
    if (page !== 1) {
      setPage(page - 1);
    }
  };

  const handleNext = (event) => {
    event.stopPropagation();
    if (page !== totalPage) {
      setPage(page + 1);
    }
  };

  const arrowStyle = {
    color: "rgba(190, 193, 196, 0.8)",
    position: "absolute",
    marginTop: "45%",
    fontSize: 60,
  };

  return (
    <div>
      <Dialog
        open={pop}
        sx={{
          width: width * 0.55 > height ? height : width * 0.55,
          marginLeft: "20%",
          height: width * 0.55 > height ? height : width * 0.55,
          borderRadius: "51%",
          border: "3px solid black",
          overflow: "hidden",
          marginTop: "auto",
          "@media(max-width: 1000px)": {
            width: width * 0.8 > height ? height : width * 0.8,
            marginLeft: "10%",
            height: width * 0.8 > height ? height : width * 0.8,
            marginTop: "auto",
          },
        }}
        PaperProps={{
          style: {
            margin: 0,
            width: "100%",
            height: "100%",
            maxHeight: "100%",
            maxWidth: "100%",
            borderRadius: "50%",
          },
        }}
      >
        <div
          style={{
            display: "inline-block",
            position: "relative",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            borderRadius: "10%",
          }}
        >
          <img
            src={
              dataLst && dataLst[page - 1]
                ? dataLst[page - 1]["originalFileUrl"]
                : "https://particledetection-aivs-processed.s3.us-west-1.amazonaws.com/original/aivflogo2023.png"
            }
            alt="not available"
            onClick={() => setPop(false)}
            style={
              dataLst && dataLst[page - 1] && dataLst[page - 1]["originalFileUrl"] === "https://particledetection-aivs-processed.s3.us-west-1.amazonaws.com/original/aivflogo2023.png"
                ?
                {
                width: "auto",
                height: "100%",
                "@media(max-width: 1000px)": {
                  marginLeft: "-5%",
                  height: width * 0.8,
                },
                }:
              {
              width: "auto",
              height: "100%",
              marginLeft: dataLst && dataLst[page - 1] ? "-17%" : null,
              "@media(max-width: 1000px)": {
                marginLeft: "-17%",
                height: width * 0.8,
              },
              }
            }
          />
        </div>
        {page !== 1 && (
          <ArrowBackIosNewIcon
            sx={[arrowStyle, { marginRight: "80%" }]}
            fontSize="large"
            onClick={handlePrev}
          />
        )}

        {page !== totalPage && (
          <ArrowForwardIosIcon
            // fontSize="large"
            sx={[arrowStyle, { marginLeft: "90%" }]}
            onClick={handleNext}
          />
        )}
      </Dialog>
    </div>
  );
}

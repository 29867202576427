import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { cleanTrap, fetchDataLst, fetchScrollInfo, fetchTrapInfo, updateTrap, fetchScrollInfoRefresh } from "redux/slices/dataSlice";

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';

export default function ModalForm({ open, setOpen, pageNum }) {

    const dispatch = useDispatch();
    const { trapId, location, building, trapName, brightness, frequency } = useSelector((state) => state.data);
    const { filter, ascend, currTeam } = useSelector((state) => state.auth);


    const [newLocation, setLocation] = useState(location);
    const [newBuilding, setBuilding] = useState(building);
    const [newTrapName, setTrapName] = useState(trapName);
    const [newFrequency, setFrequency] = useState(frequency);
    const [newBrightness, setBrightness] = useState(brightness);

    const [invalidTrapName, setInvalidTrapName] = useState(false);
    const [invalidLocation, setInvalidLocation] = useState(false);
    const [invalidBuilding, setInvalidBuilding] = useState(false);

    useEffect(() => {
        if (newTrapName.length >= 21) {
            setInvalidTrapName(true);
        } else {
            setInvalidTrapName(false);
        }
        if (newLocation.length >= 21) {
            setInvalidLocation(true);
        } else {
            setInvalidLocation(false);
        }
        if (newBuilding.length >= 21) {
            setInvalidBuilding(true);
        } else{
            setInvalidBuilding(false);
        }

    }, [newTrapName, newLocation, newBuilding]);

    const BrightnessScale = [
        {label: 'lvl 1', value: '1'},
        {label: 'lvl 2', value: '2'},
        {label: 'lvl 3', value: '3'},
        {label: 'lvl 4', value: '4'},
        {label: 'lvl 5', value: '5'},
        {label: 'lvl 6', value: '6'},
        {label: 'lvl 7', value: '7'},
        {label: 'lvl 8', value: '8'},
        {label: 'lvl 9', value: '9'},
    ];
    
    const FrequencyScale = [
        {label: '5 minutes', value: '1'},
        {label: '4 hours', value: '6'},
        {label: '8 hours', value: '2'},
        {label: '24 hours', value: '3'},
        {label: '48 hours', value: '4'},
    ];
  
    const handleTrpNameChange = (event) => {
      setTrapName(event.target.value);
    };
  
    const handleBuildingChange = (event) => {
      setBuilding(event.target.value);
    };
  
    const handleLocationChange = (event) => {
      setLocation(event.target.value);
    };
  
    const handleFreqChange = (event) => {
      setFrequency(event.target.value);
    };
  
    const handleBrightnessChange = (event) => {
        setBrightness(event.target.value);
    };
  
    const handleClose = () => {
        setOpen(false);
    };
  
    const submitValues = async () => {
        if (!invalidTrapName && !invalidLocation && !invalidBuilding) {
            console.log(newFrequency);
            let _minutes = null;
            let _hours = null;
            let _days = null;

            if (newFrequency == 1) {
                _minutes = 5;
                _hours = 0;
                _days = 0;
            } else if (newFrequency == 6) {
                _minutes = 0;
                _hours = 4;
                _days = 0;
            } else if (newFrequency == 2) {
                _minutes = 0;
                _hours = 8;
                _days = 0;
            } else if (newFrequency == 3) {
                _minutes = 0;
                _hours = 0;
                _days = 1;
            } else if (newFrequency == 4) {
                _minutes = 0;
                _hours = 0;
                _days = 2;
            }

            let msg = {
                location: newLocation.trim(),
                building: newBuilding.trim(),
                trapName: newTrapName.trim(),
                frequency: newFrequency,
                minutes: _minutes,
                hours: _hours,
                days: _days,
                brightness: newBrightness,
                id: trapId,
            };

            dispatch(updateTrap(msg));
            dispatch(fetchTrapInfo(trapId));
            setLocation("");
            setBuilding("");
            setTrapName("");
            setInvalidTrapName(false);
            setInvalidBuilding(false);
            setInvalidLocation(false);
            setOpen(false);
            
            let payload = {
                msg: {
                    filter: filter,
                    ascend: ascend,
                    teamName: currTeam,
                },
                num: pageNum,
            };
            let response = await dispatch(fetchScrollInfoRefresh(payload));
        }
    };

    useEffect(() => {
        dispatch(fetchTrapInfo(trapId));
    });

    return (
        <div>
            <Dialog open={open} onClose={handleClose}  fullWidth maxWidth='xs'>
                <DialogTitle>SmartProbe Information Update</DialogTitle>
                <DialogContent>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="outlined-size-normal"
                      label="SmartProbe Name"
                      value={newTrapName}
                      onChange={handleTrpNameChange}
                      placeholder="Add SmartProbe Name"
                    />
                    {invalidTrapName && <FormHelperText style={{color : "red"}}>Cannot exceed 20 characters</FormHelperText>}
                    <TextField
                        fullWidth
                        margin="normal"
                        id="outlined-size-normal"
                        label="Building"
                        value={newBuilding}
                        onChange={handleBuildingChange}
                        placeholder="Add Building Name..."
                    />
                    {invalidBuilding && <FormHelperText style={{color : "red"}}>Cannot exceed 20 characters</FormHelperText>}
                    <TextField
                        fullWidth
                        margin="normal"
                        id="outlined-size-normal"
                        label="Location"
                        value={newLocation}
                        onChange={handleLocationChange}
                        placeholder="Add Location Name..."
                    />
                    {invalidLocation && <FormHelperText style={{color : "red"}}>Cannot exceed 20 characters</FormHelperText>}
                    <div>
                        <TextField
                            id="outlined-select-currency"
                            select
                            margin="normal"
                            label="Frequency"
                            value={newFrequency}
                            onChange={handleFreqChange}
                            helperText="Please select your frequency"
                        >
                            {FrequencyScale.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div>
                        <TextField
                            id="outlined-select-currency"
                            select
                            margin="normal"
                            label="Brightness"
                            value={newBrightness}
                            onChange={handleBrightnessChange}
                            helperText="Please select your brightness"
                        >
                            {BrightnessScale.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={submitValues}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </div>

    );
};
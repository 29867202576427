/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './account-box.sass';

import { useSelector, useDispatch } from "react-redux";
import { toggleTempUnit, fetchUserLst } from "redux/slices/authSlice";
import AccountThreshold from 'components/box/account-box/AccountThreshold/AccountThreshold';

import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "red",
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "red",
    },
    '& .MuiSwitch-switchBase' : {
      color: "#1870d5",
    },
    '& .MuiSwitch-track': {
      backgroundColor: "#1870d5",
    },
    
}));

export default function AccountFeatures(){

    const dispatch = useDispatch();
    const { fahrenheit } = useSelector((state) => state.auth);

    const [isEnabled, setIsEnabled] = useState(false);
    const [temp, setTemp] = useState("");

    const toggleUnits = async (event) => {
        const body = { };
        setIsEnabled(event.target.isEnabled);
        await dispatch(toggleTempUnit(body));
        await dispatch(fetchUserLst());
    };

    
    useEffect(() => {
        if(fahrenheit) {
            setIsEnabled(false);
        } else {
            setIsEnabled(true);
        }
    });

    useEffect(() => {
        if (fahrenheit) {
            setTemp("Fahrenheit");
        } else {
            setTemp("Celsius");
        }    
    }, [fahrenheit]);
   
    return (
        <div className="account-box">
            <div className="account-container">
                <div className="account-title">
                    <h2>Temperature Units</h2>
                    <p>{temp}</p>
                </div>
                <div className="account-btn">
                    <CustomSwitch
                        checked={isEnabled}
                        onChange={toggleUnits}
                    />
                </div>
            </div>
        </div>
    );
}
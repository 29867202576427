import React, { useEffect, useState } from 'react';
import './account-box.sass';

import { useSelector, useDispatch } from "react-redux";
import { fetchUserLst, updateAccount } from "redux/slices/authSlice";

import ChangePasswordModal from 'components/modal/ChangePasswordModal';


export default function AccountBox(){

    const dispatch = useDispatch();
    const { email, company } = useSelector((state) => state.auth);
    
    const [form, setForm] = useState(false);
    const [companyName, setCompanyName] = useState('');

    // Controls when the modal form opens
    const [open, setOpen] = useState(false);

    const cancel = () => {
        setForm(false);
        setCompanyName('');
    }

    const submitValues = async() => {

        let user = {
            company: companyName,
        };

        dispatch(updateAccount(user));
        await dispatch(fetchUserLst());

        setCompanyName('');
        setForm(false);

    };

    return (
        <div className="account-box">
            <div className="account-container">
                <div className="account-title">
                    <h2>Email</h2>
                    <p>{email}</p>
                </div>
            </div>
            <div className="account-container">
                <div className="account-title">
                    <h2>Password</h2>
                    <p>**HIDDEN**</p> 
                </div>
                <div className="account-btn">
                    <button className="account-button" onClick={() => setOpen(true)}>Change</button>
                </div>
            </div>
            <div className="account-container">
                <div className="account-title">
                    <h2>Company Name</h2>
                    {form ? (
                        <input 
                            type="text"
                            className="changeAccountInfoInput" 
                            value={companyName}  
                            name="Company Name" 
                            onChange={e => setCompanyName(e.target.value)} 
                            placeholder={company ? company : "Company Name"}
                        />
                    ) : (
                        <p>{company ? company : "Company Name"}</p> 
                    )}
                </div>
                <div className="account-btn">
                    {form ? (
                        <button className="account-button" onClick={() => cancel()}>Cancel</button>
                    ) : (
                        <button className="account-button" onClick={() => setForm(true)}>Edit</button>
                    )
                    }
                </div>
            </div>
            <div className="submit-btn">
                {form ? (
                    <button className="account-button" onClick={() => submitValues()}>Submit</button>
                ) : (
                    <></>
                )}
            </div>
            {open && <ChangePasswordModal open={open} setOpen={setOpen}/>}
        </div>
    );
};
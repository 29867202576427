import React, { useEffect, useState } from 'react';
import './upload-box.sass';

import { useSelector, useDispatch } from "react-redux";
import { fetchUserLst, updateAccount } from "redux/slices/authSlice";
import { uploadData } from "redux/slices/dataSlice";
import {DropzoneDialog} from 'material-ui-dropzone'
import Button from '@mui/material/Button';


export default function AccountBox(){

    const dispatch = useDispatch();
    const { email, company } = useSelector((state) => state.auth);
    /*
    useEffect(() => {
        dispatch(fetchUserLst());
    });
    */
    const [form, setForm] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [loading, setLoading] = useState(false);

    // Controls when the modal form opens
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const handleSave = (files) => {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++){
            if(files[i].type == 'image/jpeg'){
                formData.append(`image`, files[i]);
            }
            if(files[i].type == 'text/csv'){
                formData.append(`csv`, files[i]);
            }
        }
        
        //for (const value of formData.values()) {
            //console.log(value);
          //}
        dispatch(uploadData(formData));
        setOpen(false);
    }

    const handleOpen = () => {
        setOpen(true);
    }
    const cancel = () => {
        setForm(false);
        setCompanyName('');
    }

    return (
        <div className="upload-box">
            <div className="upload-container"> 
                <div className="upload-title">
                    <h2>Upload</h2>
                    <div  className="upload-paragraph" >From 1 SmartProbe ID folder, drag and drop the folder or select all the images and csv file</div>
                </div>
            </div>
            <div className="upload-container">
            <div>
            <button className="account-button" onClick={handleOpen}>Add Data</button>
                <DropzoneDialog
                    open={open}
                    onSave={handleSave.bind(this)}
                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'text/csv']}
                    showPreviews={true}
                    maxFileSize={50000000000}
                    filesLimit={10000}
                    onClose={handleClose}
                />
            </div>
            </div>
        </div>
    );
};
import React, { useEffect, useState } from 'react';
import './accountEmail.sass';

import { useSelector, useDispatch } from "react-redux";
import { fetchUserLst, toggleEmailNoti,
    toggleEmailNotiDay,
    removeEmail, addEmail
 } from "redux/slices/authSlice";

import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import Modal from 'react-modal';

// Set the app element
Modal.setAppElement('#root');

const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "grey",
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "grey",
    },
    '& .MuiSwitch-switchBase' : {
      color: "#1870d5",
    },
    '& .MuiSwitch-track': {
      backgroundColor: "#1870d5",
    },
    
}));

export default function AccountEmail(){

const dispatch = useDispatch();
const { emailNoti, emailNotiDays, emailList } = useSelector((state) => state.auth);

  const [isEnabled, setIsEnabled] = useState(false);
  const [isMonday, setIsMonday] = useState(emailNotiDays.Monday);
  const [isTuesday, setIsTuesday] = useState(emailNotiDays.Tuesday);
  const [isWednesday, setIsWednesday] = useState(emailNotiDays.Wednesday);
  const [isThursday, setIsThursday] = useState(emailNotiDays.Thursday);
  const [isFriday, setIsFriday] = useState(emailNotiDays.Friday);
  const [isSaturday, setIsSaturday] = useState(emailNotiDays.Saturday);
  const [isSunday, setIsSunday] = useState(emailNotiDays.Sunday);
  const [temp, setTemp] = useState("");

  useEffect(() => {
    dispatch(fetchUserLst());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const daybutton = async (day) => {
    let msg = { day: day };
    if (day == "Monday") {
      setIsMonday((previousState) => !previousState);
      msg.day = "Monday";
    } else if (day == "Tuesday") {
      setIsTuesday((previousState) => !previousState);
      msg.day = "Tuesday";
    } else if (day == "Wednesday") {
      setIsWednesday((previousState) => !previousState);
      msg.day = "Wednesday";
    } else if (day == "Thursday") {
      setIsThursday((previousState) => !previousState);
      msg.day = "Thursday";
    } else if (day == "Friday") {
      setIsFriday((previousState) => !previousState);
      msg.day = "Friday";
    } else if (day == "Saturday") {
      setIsSaturday((previousState) => !previousState);
      msg.day = "Saturday";
    } else if (day == "Sunday") {
      setIsSunday((previousState) => !previousState);
      msg.day = "Sunday";
    }
    await dispatch(toggleEmailNotiDay(msg));
    await dispatch(fetchUserLst());
  };
  const toggleEmail = async (event) => {
    let body = {};
    setIsEnabled((previousState) => !previousState);
    await dispatch(toggleEmailNoti(body));
    await dispatch(fetchUserLst());
    };
  useEffect(() => {
    if (!emailNoti) {
      setIsEnabled(false);
    } else {
      setIsEnabled(true);
    }
  }, []);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalRemoveIsOpen, setModalRemoveIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [removeValue, setRemoveValue] = useState('');
  const handleClick = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const openRemoveModal = (payload) => {
    setRemoveValue(payload);
    setModalRemoveIsOpen(true);
  };
  const closeRemoveModal = () => {
    setModalRemoveIsOpen(false);
  };
  const handleSubmit = () => {
    // Your submit logic here
    addEmailHandler(inputValue);
    setInputValue("");
    closeModal();
  };

  const addEmailHandler = async (payload) => {
    let body = { email: payload };
    try {
      await dispatch(addEmail(body));
      await dispatch(fetchUserLst());
    } catch (e) {
      console.log(e);
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const removeEmailHandler = async () => {
    let body = { email: removeValue };
    try {
      await dispatch(removeEmail(body));
      await dispatch(fetchUserLst());
      setModalRemoveIsOpen(false);

    } catch (e) {
      console.log(e);
    }
  };
    return (
        <div className="account-box">
            <div className="account-container">
                <div className="account-title">
                    <h2>Email</h2>
                    <div className="account-division-container">
                        <div className="account-days-container">
                            <div className="row-container">
                                <div> {emailNoti ? 
                                    <div className="switch-header-text">Active</div> : 
                                    <div className="switch-header-text">Not Active</div>}
                                </div>
                                <div className="account-btn">
                                    <CustomSwitch
                                        checked={!isEnabled}
                                        onChange={toggleEmail}
                                    />
                                </div>
                            </div>
                            <div className="row-container-days">
                                <div className="switch-text">Monday</div>
                                <div className="account-btn">
                                    <CustomSwitch
                                        checked={!isMonday}
                                        onChange={() => daybutton("Monday")}
                                        value={isMonday}
                                    />
                                </div>
                            </div> 
                            <div className="row-container-days">
                                <div className="switch-text">Tuesday</div>
                                <div className="account-btn">
                                    <CustomSwitch
                                        checked={!isTuesday}
                                        onChange={() => daybutton("Tuesday")}
                                        value={isTuesday}
                                    />
                                </div>
                            </div>
                            <div className="row-container-days">
                                <div className="switch-text">Wednesday</div>
                                <div className="account-btn">
                                    <CustomSwitch
                                        checked={!isWednesday}
                                        onChange={() => daybutton("Wednesday")}
                                        value={isWednesday}
                                    />
                                </div>
                            </div>  
                            <div className="row-container-days">
                                <div className="switch-text">Thursday</div>
                                <div className="account-btn">
                                    <CustomSwitch
                                        checked={!isThursday}
                                        onChange={() => daybutton("Thursday")}
                                        value={isThursday}
                                    />
                                </div>
                            </div> 
                            <div className="row-container-days">
                                <div className="switch-text">Friday</div>
                                <div className="account-btn">
                                    <CustomSwitch
                                        checked={!isFriday}
                                        onChange={() => daybutton("Friday")}
                                        value={isFriday}
                                    />
                                </div>
                            </div> 
                            <div className="row-container-days">
                                <div className="switch-text">Saturday</div>
                                <div className="account-btn">
                                    <CustomSwitch
                                        checked={!isSaturday}
                                        onChange={() => daybutton("Saturday")}
                                        value={isSaturday}
                                    />
                                </div>
                            </div> 
                            <div className="row-container-days">
                                <div className="switch-text">Sunday</div>
                                <div className="account-btn">
                                    <CustomSwitch
                                        checked={!isSunday}
                                        onChange={() => daybutton("Sunday")}
                                        value={isSunday}
                                    />
                                </div>
                            </div>
                            </div>
                            <div className="right-container">
                                <div className="email-list-title-container">
                                    <div className="email-list-title"> 
                                        Email List
                                    </div>
                                    <div className="email-list-text">
                                        Add up to 5 emails to receive notification
                                    </div>
                                    <div className="email-list-emails">
                                        {emailList.map((num, idx) => (
                                            <div className="email-list-items" key={idx}>
                                                <div className="">
                                                    {num}
                                                </div>
                                                <div className="remove-item" onClick={() => openRemoveModal(num)}>
                                                    -
                                                </div>
                                            </div>
                                        ))}
                                    </div>                                 
                                    <button className="email-button" onClick={handleClick}>Add Email</button>          
                                    <Modal
                                        isOpen={modalIsOpen}
                                        onRequestClose={closeModal}
                                        contentLabel="Example Modal"
                                        className="myModal"

                                    >
                                        <h2>Enter Email</h2>
                                        <input type="text" value={inputValue} onChange={handleInputChange} />
                                        <div className="email-button-input-container">
                                        <button  className="email-button-modal" onClick={closeModal}>Cancel</button>
                                        <button className="email-button-modal" onClick={handleSubmit}>Add</button>
                                        </div>
                                    </Modal>
                                    <Modal
                                        isOpen={modalRemoveIsOpen}
                                        onRequestClose={closeModal}
                                        contentLabel="Example Modal"
                                        className="myModalRemove"

                                    >
                                        <h2>Delete: </h2>
                                        <div className="email-list-text">
                                            {removeValue}
                                        </div>
                                        <div className="email-button-input-container-remove">
                                        <button  className="email-button-modal" onClick={closeRemoveModal}>Cancel</button>
                                        <button className="email-button-modal" onClick={removeEmailHandler}>Delete</button>
                                        </div>

                                    </Modal>
                                </div>
            
                               
                        </div>             
                        </div>   
                        
                </div>
                
        </div>
    </div>
    );
}
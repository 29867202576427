import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./info-bar-2.sass";

import editBtn from "assets/edit.png";

import InsectCountModal from "components/modal/InsectCountModal";


export default function ParticleCountInfoBar({ value, num, pageNum }) {
  let _title = "Insect Count";
  let _value;
  if (value === null) {
    _value = "None";
  } else {
    _value = value != -1 ? value : "X";
  }
  

  const { dataLst } = useSelector((state) => state.data);

  const [open, setOpen] = useState(false);
  const [showEditBtn, setShowEditBtn] = useState();

  useEffect(() => {
    if (dataLst === null) {
      setShowEditBtn(false);
    } else {
      setShowEditBtn(true);
    }
  }, [dataLst]);

  return (
    <div className="info-bar">
      <div className="info-bar-title">
        <span>{_title}</span>
      </div>
      <div className="info-bar-content">
        {_value == "X" ? (
          <div className = "is-x">{_value}</div>
        ) : (
          <div>{_value}</div>
        )}
      </div>
      <div className="edit-btn-content">
        {showEditBtn ? (
          <img 
            alt="edit"
            src={editBtn}
            className="edit-btn"
            onClick={() => setOpen(true)}
          />
        ) : (
          <></>
        )}
      </div>
      {open && <InsectCountModal open={open} setOpen={setOpen} page={num} pageNum={pageNum} />}
    </div>
  );
};

import React, { useState, useEffect} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { verifyAcc, resend, setVerifyError } from "redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import VerificationInput from "react-verification-input";
import "./verification.sass";

export default function Verification({ goBack }) {

    const navigate = useHistory();

    const location = useLocation();
    const email = location.state.email;
    const password = location.state.password;
    console.log(email);
    console.log(password);

    const dispatch = useDispatch();
    const errorMsg = useSelector((state) => state.auth.verifyError);
    const [errorDisplay, setErrorDisplay] = useState("");
    const [code, setCode] = useState("");

    // const clearErrorMsg = () => {
    //     dispatch(setVerifyError(""));
    //     setErrorDisplay("");
    // };

    const handleVerify = async () => {

        let data = {
            email: email,
            code: code,
        };

        dispatch(verifyAcc(data))
        .unwrap()
        .then(() => {
            navigate.push("/verifySuccess", { email: email, password: password })
        })
        .catch(() => {
           console.log("Error, don't navigate to next screen");
        });

    };

    const handleResend = async () => {
        console.log("Re-send verification code");
        setCode("");
        dispatch(resend({ email: email }));
    };

    useEffect(() => {
        setErrorDisplay(errorMsg);
    }, [errorMsg]);

    useEffect(() => {
        console.log(code);
    }, [code]);

    return (
        <div className="verification-container">
            <div className="verify-container">
                <h1 className="verify-title">Verification</h1>
                <p className="verify-txt">Please enter the verification code sent to your email address</p>
                <div className="verification-input-container">
                    <VerificationInput 
                        length={6} 
                        validChars="0-9" 
                        inputProps={{ type: "tel" }} 
                        placeholder="_"
                        onChange={setCode}
                    />
                </div>
                <div className="verify-registration-error">
                    {errorDisplay && <label className="verify-registration-error-text">{errorDisplay}</label>}
                </div>
                <div className="verify-btn-container">
                    <button 
                        className="verify-btn"
                        onClick={() => handleVerify()}
                    >
                        Confirm
                    </button>
                    <button 
                        className="verify-btn"
                        onClick={() => handleResend()}
                    >
                        Re-send
                    </button>
                </div>
            </div>
        </div>
    );
};
import React from 'react';
import './upload.sass';
import UploadBox from 'components/box/upload-box/UploadBox';

export default function Account(){
    return (
        <div className="upload">
            <div className="upload-body">
                <div className="upload-title-container">
                    <h1>Upload SmartProbe Data</h1>
                </div>
                <div>
                    <UploadBox />
                </div>
            </div>
        </div>
    );
}


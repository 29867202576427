import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";
import "./home.sass";

import LoginPanel from "components/login-block/LoginPanel";
import RegisterPanel from "components/login-block/RegisterPanel";
import Verification from "components/login-block/Verification";
import ContinueProcess from "components/login-block/ContinueProcess";
import VerifySuccess from "components/login-block/VerifySuccess";

import DeleteAccount from "components/login-block/DeleteAccount";

import ForgotPassword from "components/login-block/ForgotPassword";
import CheckEmail from "components/login-block/CheckEmail";
import SkipForgotPassword from "components/login-block/SkipForgotPassword";
import CreateNewPassword from "components/login-block/CreateNewPassword";
import CreateNewPasswordSuccess from "components/login-block/CreateNewPasswordSuccess";

export default function Home() {

  const [open, setOpen] = useState(true);

  const goBack = () => {
    setOpen(true);
  }

  // useEffect(() => {
  //   console.log(open);
  // }, [open]);

  return (
    <Router>
      <div className="home">
        <div className="home-left-block"></div>
        <div className="home-right-block">
          {open ? (
            <div>
              <div className="home-page-switcher">
              <NavLink
                exact
                to="/"
                activeClassName="home-page-switcher-item-active"
                className="home-page-switcher-item"
              >
                Login
              </NavLink>
              <NavLink
                to="/register"
                activeClassName="home-page-switcher-item-active"
                className="home-page-switcher-item"
              >
                Register
              </NavLink>
            </div>
            <div className="FormHeader">SmartProbe</div>
            <div className="FormTitle">
              <NavLink
                exact
                to="/"
                activeClassName="FormTitle_Link-Active"
                className="FormTitle_Link"
              >
                Login
              </NavLink>{" "}
              or{" "}
              <NavLink
                to="/register"
                activeClassName="FormTitle_Link-Active"
                className="FormTitle_Link"
              >
                Register
              </NavLink>
            </div>
          </div>
          ) : (
            <></>
          )}
          
          <Route className="home-login-panel" exact path="/">
            <LoginPanel setOpen={setOpen}/>
          </Route>
          <Route className="home-login-panel" path="/register">
            <RegisterPanel setOpen={setOpen}/>
          </Route>
          <Route  path="/verification">
            <Verification goBack={goBack}/>
          </Route>
          <Route path="/continueProcess">
            <ContinueProcess goBack={goBack}/>
          </Route>
          <Route path="/verifySuccess">
            <VerifySuccess goBack={goBack}/>
          </Route>
          <Route path="/forgotPassword">
            <ForgotPassword goBack={goBack}/>
          </Route>
          <Route path="/deleteAccount">
            <DeleteAccount goBack={goBack}/>
          </Route>
          <Route path="/checkEmail">
            <CheckEmail />
          </Route>
          <Route path="/skipForgotPassword">
            <SkipForgotPassword />
          </Route>
          <Route path="/createNewPassword">
            <CreateNewPassword />
          </Route>
          <Route path="/createNewPasswordSuccess">
            <CreateNewPasswordSuccess goBack={goBack}/>
          </Route>
          <Redirect to="/" />
        </div>
      </div>
    </Router>
  );
}

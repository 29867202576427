import React from "react";
import "./info-bar-2.sass";
import { useSelector } from "react-redux";


export default function InfoBar2({ title, value }) {
  let _title = title;
  let _value = value;

  if (title === "Date") {
    if (_value !== null) {
      // creates an array: ["11/15/2022,", "6:12:02", "PM"]
      let dateLst = value.split(" ");
      // remove comma from date by replace
      _value = dateLst[0].replace(/,/g, '');
    } else {
      _value = "None";
    }
  }

  if (title === "Time"){
    if (_value !== null) {
      // creates an array: ["11/15/2022,", "6:12:02", "PM"]
      let timeLst = value.split(" ");

      let timeLst2 = timeLst[1].split(":");

      // create and slice time together
      _value = timeLst2[0]+ ":" + timeLst2[1] + " " + timeLst[2];
    } else {
      _value = "None";
    }
  }

  const { fahrenheit } = useSelector((state) => state.auth);

  if (title === "trapId") {
    _title = "SmartProbe ID";
  } else if  (title === "humidity") {
    _title = "Realtive Humidity (%)";
    if (_value !== null) {
      _value = parseInt(value)
    } else {
      _value = "None"
    }
  } else if  (title === "temperature") {
    _title = "Temperature";
    if (_value !== null) {
      if (fahrenheit) {
        _value = parseInt((9 / 5) * value + 32) + " °F";
      } else {
        _value = parseInt(value) + " °C";
      }
    } else {
      _value = "None";
    }
  } else if  (title === "particleCount") {
    _title = "Insect Count"
  } else if  (title === "batteryInfo") {
    _title = "Battery"
  } else if  (title === "wifiInfo") {
    _title = "Wifi"
  }

  return (
    <div className="info-bar">
      <div className="info-bar-title">
        <span>{_title}</span>
      </div>
      <div className="info-bar-content">
        {_value}
      </div>
      <div className="edit-btn-content"></div>
    </div>
  );
}

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { downloadData } from 'redux/slices/dataSlice';


export default function DownloadDataModal({ open, setOpen }) {

    const dispatch = useDispatch();
    const { currTeam } = useSelector(
        (state) => state.auth
      );
    const navigate = useHistory();

    const handleClose = () => {
        navigate.push("/");
        setOpen(false);
    };

    const download = async () => {
        const msg = {"teamName":currTeam}
        dispatch(downloadData(msg));
        navigate.push("/");
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Download Data"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Confirm to download data from all your SmartProbes. This will include pervious sessions that have been cleaned.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()}>Cancel</Button>
                    <Button onClick={() => download()}>
                        Download
                    </Button>
                </DialogActions>
            </Dialog>
    </div>
  );
}
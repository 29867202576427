import React, { useEffect } from "react";
import "./App.sass";

import { useSelector, useDispatch } from "react-redux";
import { verify } from "redux/slices/authSlice";

import Home from "pages/Home/Home";
import NavBar from "components/navbar/NavBar";
import Dashboard from "pages/Dashboard/Dashboard";
import Account from "pages/Account/Account";
import Upload from "pages/Upload/Upload";
import Footer from "components/footer/Footer";

import { BrowserRouter, Route, Switch } from "react-router-dom";

function App() {

  const dispatch = useDispatch();
  const authenticate = useSelector((state) => state.auth.authenticate);

  useEffect(() => {
    dispatch(verify());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   console.log("Authentication: " + authenticate);
  //   // if(authenticate) {
  //   //   dispatch(verify());
  //   // }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [authenticate]);

  return (
    <div className="canvas">
      {authenticate ? (
        <div className="main">
          <BrowserRouter>
            <NavBar />  
              <div>
                <Switch>
                  <Route path="/" component={Dashboard} exact />
                  <Route path="/account" component={Account} />
                  <Route path="/upload" component={Upload} />
                </Switch>
              </div>
            <Footer />
          </BrowserRouter>
        </div>
      ) : (
        <Home />
      )}
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import "./chart-box.sass";
import { Line, defaults } from "react-chartjs-2";

import { useSelector } from "react-redux";

import Toggle from "components/toggle/Toggle";
import DropDown from "components/toggle/DropDown";

defaults.global.legend.display = false;

export default function ChartBox() {

  
  const { dataLst } = useSelector((state) => state.data);

  const [isToggle, setToggle] = useState('temperature');
  
  const [graphTitle, setGraphTitle] = useState('Temperature (°C)');
  const [graphLabel, setGraphLabel] = useState('temperature');
  const [graphColor, setGraphColor] = useState('rgb(24,112,213)');
  
  const [valueSet, setValueSet] = useState([]);
 
  const [timeSet, setTimeSet] = useState([]);
  const [xaxis, setXAxis] = useState([]);

  useEffect(() => {
    if (dataLst) {
      let _valueSet = [];
      let _labelSet = [];
      let _timeSet = [];
      let _axisSet = [];
      dataLst
      .filter((data) => data["particleCount"] !== -1)
        .slice(0,7)
        .reverse()
        .forEach((item, idx) => {
          let key = isToggle;
          _valueSet.push(parseInt((item[key])));
          _labelSet.push("#" + idx);

          let lst = item["fileName"].split("-");
          let timestamp = [
            lst.slice(2, 4).join("-") + ("-") + lst.slice(1,2).join("-"),
            ("(") + lst.slice(4, 6).join(":") + (")"),
          ].join(" ");
          _timeSet.push(timestamp);

          let axis = [
            lst.slice(2, 4).join("/")
          ].join(" ");
          _axisSet.push(axis);

        });
    
      setValueSet(_valueSet);
      setTimeSet(_timeSet);
      setXAxis(_axisSet);
    }
  }, [dataLst, isToggle]);

  const textStyle = {
    fontSize: "25px",
    fontWeight: "bold",
  };

  useEffect(() => {
    if(isToggle === "temperature") {
      setGraphTitle("Temperature (°C)");
      setGraphLabel("temperature");
      setGraphColor('rgb(24,112,213)');
    } else if(isToggle === "humidity") {
      setGraphTitle("Relative Humidity (%)");
      setGraphLabel("realtive humidity");
      setGraphColor('rgb(24,112,213, 0.6)');
    } else {
      setGraphTitle("Insect Count");
      setGraphLabel("insect count");
      setGraphColor('rgb(24,112,213, 0.2)');
    }
  }, [isToggle]);


  return (
    <div className="chart-box">
      <div className="head-bar">
        <div className="title">
          <text className="title-txt">{graphTitle}</text>
        </div>
        <div className="toggle-box">
          <DropDown setToggle={setToggle} />
        </div>
      </div>
      <div className="content">
        {dataLst !== null ? (
          <div className="chart">
            <Line
              data={{
                labels: xaxis,
                datasets: [
                  {
                    label: graphLabel,
                    data: valueSet,
                    backgroundColor: "rgb(24,112,213,0.4)",
                    borderColor: graphColor
                  },
                ],
              }}
              options={{
                scales: {
                  yAxes: [{
                    ticks: {
                      precision: 0,
                    }
                  }]
                },
                maintainAspectRatio: false,
                tooltips: {
                  mode: "label",
                  callbacks: {
                    title: function (tooltipItem, data) {
                      return "timestamp: " + timeSet[tooltipItem[0].index];
                    },
                  },
                },
              }}
            />
          </div>
        ) : (
          <div>
            <p style={textStyle}>No Data</p>
          </div>
        )}
      </div>
    </div>
  );
}

import { createSlice, createAsyncThunk, createDraftSafeSelector } from "@reduxjs/toolkit";
import axios from "axios";
const FileDownload = require('js-file-download');

const DataView = {
  trapId: 0,
  trapLst: null,
  dataLst: null,
  frequency: -1,
  brightness: -1,
  scrollInfo: null,
  location: null,
  building: null,
  trapName: null,
  minutes: null,
  hours: null,
  days: null,
  pwm: null,
  loading: true,
};

export const fetchTrapLst = createAsyncThunk("data/trapLst", async () => {
  let response = await axios.get("/api/users/trap", {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("GrainGuardianToken"),
    },
  });
  return response.data;
});

export const fetchTrapInfo = createAsyncThunk("data/trapInfo", async (id) => {
  let response = await axios.get("/api/traps/" + id, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("GrainGuardianToken"),
    },
  });
  response.data.trap.id = id;
  return response.data;
});

export const changeBrightness = createAsyncThunk(
  "data/brightness",
  async (payload) => {
    let body = {
      trap: {
        brightness: payload.brightness,
      },
    };
    let response = await axios.put(
      "/api/traps/brightness/" + payload.id,
      body,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("GrainGuardianToken"),
        },
      }
    );
    return response.data;
  }
);

export const changeFrequency = createAsyncThunk(
  "data/frequency",
  async (payload) => {
    let body = {
      trap: {
        frequency: payload.frequency,
      },
    };
    let response = await axios.put("/api/traps/frequency/" + payload.id, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("GrainGuardianToken"),
      },
    });
    return response.data;
  }
);

export const updateTrap = createAsyncThunk(
  "data/updateTrap",
  async (payload) => {
    const token = await localStorage.getItem("GrainGuardianToken");
    let body = {
      trap: {
        trapName: payload.trapName,
        location: payload.location,
        building: payload.building,
        frequency: payload.frequency,
        brightness: payload.brightness,
        minutes: payload.minutes,
        hours: payload.hours,
        days: payload.days,
        pwm: payload.pwm,
      },
    };

    let response = await axios.put("/api/traps/update/" + payload.id, body, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    return response.data;
  }
);

export const fetchDataLst = createAsyncThunk("data/dataLst", async (id) => {
  let response = await axios.get("/api/data/trapProbe/" + id, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("GrainGuardianToken"),
    },
  });
  return response.data;
});

// New fetch scroll info with query
export const fetchScrollInfo = createAsyncThunk(
  "data/scrollInfo",
  async (payload) => {
    let body = payload.msg;
    let response = await axios.put("/api/data/scrollinfo/probes/" + payload.num, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("GrainGuardianToken"),
      },
    });
    //console.log("fetchScrollInfo just finished!");
    return response.data;
  }
);

// Might not need fetch scroll info for refresh on website
export const fetchScrollInfoRefresh = createAsyncThunk(
  "data/scrollInfoRefresh",
  async (payload) => {
    let body = payload.msg;
    let response = await axios.put("/api/data/scrollinfo/probes/" + payload.num, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("GrainGuardianToken"),
      },
    });
    //console.log("fetchScrollInfoRefresh just finished!");
    return response.data;
  }
);

export const downloadData = createAsyncThunk("data/exportdataTeams", async (msg) => {
  let body = msg;
  let response = await axios.get("/api/data/exportdataTeams",{
    headers: {
      Authorization: "Bearer " + localStorage.getItem("GrainGuardianToken"),
    },
    params: body,
    responseType: 'blob',
  }).then((response) => {
      FileDownload(response.data, "AIVisionFoodDownload.xlsx");
  });
});

export const changeInsectCount = createAsyncThunk("data/changeInsectCount", async(msg) => {
  let body = msg;
  let response = await axios.post("/api/insect/data", body, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("GrainGuardianToken"),
    },
  });
  return response.data;
});

export const cleanTrap = createAsyncThunk("data/cleanTrap", async (msg) => {
  let body = msg;
  let response = await axios.post("/api/probefunctions/trapTeams/probeSession/all", body, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("GrainGuardianToken"),
    },
  });
  return response.data;

});

export const deleteCurrentData = createAsyncThunk(
  "data/deleteCurrentData",
  async (msg) => {
    let body = msg;
    let response = await axios.delete("api/probefunctions/trap/probeCurrent/delete", {
      data: body,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("GrainGuardianToken"),
      },
    });
    return response.data;
    }
  )

export const deleteAllData = createAsyncThunk(
  "data/deleteAllData",
    async (msg) => {
      let body = {
        trapId: msg.trapId.toString(),
        teamName: msg.teamName,
      };
      let response = await axios.delete("/api/probefunctions/trapTeams/probeAll/delete", {
        data: body,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("GrainGuardianToken"),
        },
      });
      //console.log("deleteAllData body: " + body);
      return response.data;
    }
)

export const removeTrap = createAsyncThunk(
  "data/removeTrap",
    async (msg) => {
      let body = {
        trapId: msg.trapId.toString(),
        teamName: msg.teamName,
      };
      let response = await axios.post("/api/data/trapTeams/session/remove", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("GrainGuardianToken"),
        },
      });
      return response.data;
    }
)

export const uploadData = createAsyncThunk("data/uploadData", async (formData) => {
  let body = formData;
  let response = await axios.post("/api/data/rasberry/allData", body, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("GrainGuardianToken"),
      "Content-Type": "multipart/form-data" ,
    }, 
    
  });
  return response.data;
});

export const clearLocalData = createAsyncThunk("data/clearLocalData", 
  async (_, { rejectWithValue }) => {
    try {
      console.log("LOG clearLocalData Ran");
      return true;
      // eslint-disable-next-line no-unreachable
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
})

export const dataSlice = createSlice({
  name: "data",
  initialState: DataView,
  reducers: {},
  extraReducers: {
    // pending
    [fetchScrollInfo.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchScrollInfoRefresh.pending]: (state, action) => {
      state.loading = true;
    },
    // fulfilled
    [fetchTrapLst.fulfilled]: (state, action) => {
      state.trapLst = action.payload.user.traps;
    },
    [fetchTrapInfo.fulfilled]: (state, action) => {
      state.frequency = parseInt(action.payload.trap.frequency);
      state.brightness = parseInt(action.payload.trap.brightness);
      state.location = action.payload.trap.location;
      state.building = action.payload.trap.building;
      state.trapName = action.payload.trap.trapName;
      state.trapId = parseInt(action.payload.trap.id);
    },
    [updateTrap.fulfilled]: (state, action) => {
      state.location = action.payload.trap.location;
      state.building = action.payload.trap.building;
      state.trapName = action.payload.trap.trapName;
      state.frequency = parseInt(action.payload.trap.frequency);
      state.brightness = parseInt(action.payload.trap.brightness);
      state.days = parseInt(action.payload.trap.days);
      state.hours = parseInt(action.payload.trap.hours);
      state.minutes = parseInt(action.payload.trap.minutes);
      state.pwm = parseInt(action.payload.trap.pwm);
    },
    [changeBrightness.fulfilled]: (state, action) => {
      state.brightness = parseInt(action.payload.trap.brightness);
      state.frequency = parseInt(action.payload.trap.frequency);
    },
    [changeFrequency.fulfilled]: (state, action) => {
      state.brightness = parseInt(action.payload.trap.brightness);
      state.frequency = parseInt(action.payload.trap.frequency);
    },
    [fetchDataLst.fulfilled]: (state, action) => {
      state.dataLst = action.payload.trap.dataLst;
    },
    [fetchScrollInfo.fulfilled]: (state, action) => {
      state.scrollInfo = action.payload;
      state.trapLst = action.payload.trapList;
      state.loading = false;
    },
    [fetchScrollInfoRefresh.fulfilled]: (state, action) => {
      state.scrollInfo = action.payload;
      state.loading = false;
    },
    [downloadData.fulfilled]: (state, action) => {},
    [uploadData.fulfilled]: (state, action) => {},
    [changeInsectCount.fulfilled]: (state, action) => {},
    // rejected
    [fetchTrapLst.rejected]: (state, action) => {
      //localStorage.removeItem("GrainGuardianToken");
      console.log("refresh please");
    },
    [fetchTrapInfo.rejected]: (state, action) => {
      //localStorage.removeItem("GrainGuardianToken");
      console.log("cannot get data");
    },
    [cleanTrap.fulfilled]: (state, action) => {},
    [clearLocalData.fulfilled]: (state, action) => {
      state.trapId = 0;
      state.trapLst = null;
      state.dataLst = null;
      state.scrollInfo = null;
      state.frequency = -1;
      state.minutes = null;
      state.hours = null;
      state.daysv = null;
      state.brightness = -1;
      state.pwm = null;
      state.location = null;
      state.building = null;
      state.trapName = null;
      state.loading = true;
    },
  },
});

export const { setError } = dataSlice.actions;
export default dataSlice.reducer;

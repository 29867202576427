/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState} from "react";
import "./profile-box.sass";
//import avatar_sample from "./assests/avatar_sample.jpg";
import WifiIndicator from "components/iconmodal/WifiIndicator";
import BatteryIndicator from "components/iconmodal/BatteryIndicator";
import InsectIndicator from "components/iconmodal/InsectIndicator";
import FilterOptionsModal from "components/modal/FilterOptionsModal";
import { useSelector, useDispatch } from "react-redux";
import { fetchTrapInfo, fetchScrollInfo, fetchScrollInfoRefresh } from "redux/slices/dataSlice";
import { fetchUserLst } from "redux/slices/authSlice";
import Btn from "components/btn/Btn";
import loadingIcon from "assets/loading.gif"
import filterIcon from "assets/filter.png";
import refreshIcon from "assets/refresh.png";
import { BsStarFill } from 'react-icons/bs';
import Pagination from '@mui/material/Pagination';

export default function Dashboard({ pageNum, handlePageNumState, scrollInfoCount, setScrollInfoCount, trapLength, setTrapLength}) {
  const dispatch = useDispatch();
  const { trapId, scrollInfo, loading } = useSelector((state) => state.data);
  const { filter, ascend, currTeam } = useSelector((state) => state.auth);
  const [filterConfirmed, setFilterConfirmed] = useState(false);
  const selectTrap = (item) => {
    if (item === trapId) {
    } 
    else {
      dispatch(fetchTrapInfo(item));
      //console.log("Selecttrap scrollInfo: ", scrollInfo);
    }
  };
  useEffect(() => {
    dispatch(fetchUserLst());
  }, []);


  //const [trapLength, setTrapLength] = useState();
  const [prevDisable, setPrevDisable] = useState(true);
  const [nextDisable, setNextDisable] = useState(true);

  const fetchTraps = async (page) => {
    let payload = {
      msg: {
        filter: filter,
        ascend: ascend,
        teamName: currTeam,
      },
      num: page,
    };
    //console.log("ProfileBox fetchTraps called, scrollInfo: ", scrollInfo);
    let response = await dispatch(fetchScrollInfo(payload));
    setScrollInfoCount(prevCount => prevCount + 1);
    setTrapLength(response.payload.trapLength);
  };

  // useEffect(() => { // This runs twice in ProfileBox. Moved to Dashboard so runs once.
  //   if (filter !== "" || ascend !== "") {
  //     console.log("filter and ascend just changed, now doing fetchTraps")
  //     fetchTraps(pageNum);
  //   }
  // }, [filter, ascend, currTeam]);

  useEffect(() => {
    if (trapLength) {
      if (pageNum - 20 === 0) {
        setPrevDisable(true);
      } else {
        setPrevDisable(false);
      }
      if (trapLength <= pageNum) {
        setNextDisable(true);
      } else {
        setNextDisable(false);
      }
    }
  });

  const next = () => {
    handlePageNumState(pageNum + 20);
    fetchTraps(pageNum + 20);
  };

  const prev = () => {
    handlePageNumState(pageNum - 20);
    fetchTraps(pageNum - 20);
  };

  // Controls when the modal form opens for filter options
  const [openFilterOptions, setOpenFilterOptions] = useState(false);

  const onRefresh = async() => {
    //console.log("Page Num: ", pageNum);
    let payload = {
      msg: {
        filter: filter,
        ascend: ascend,
        teamName: currTeam,
      },
      num: pageNum,
    };
    let response = await dispatch(fetchScrollInfoRefresh(payload));
    setTrapLength(response.payload.trapLength); //probably useless
    //console.log("onRefresh called, scrollInfo after refresh: ", scrollInfo);
  }

  const [btnDisable, setBtnDisable] = useState(false);
  useEffect(() => {
    if (loading) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [loading]);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    handlePageNumState(newPage * 20);
    fetchTraps(newPage * 20);
  };

  useEffect(() => {
    //console.log("ProfileBox useEffect Scrollinfo: ",scrollInfo);
}, [scrollInfo]);

  useEffect(() => { // Temp fix to refresh every time filter is changed
    if(filterConfirmed){
      //console.log("Profilebox: filter got confirmed");
      setFilterConfirmed(false);
      onRefresh();
    }
  }, [filterConfirmed])


//   useEffect(() => {
//   console.log("Running useEffect onRefresh()");
//   onRefresh();
// }, []);

// const [renderCount, setRenderCount] = useState(0);
// useEffect( ()=> { // To call the refresh function after scrollInfo got updated x times, doesnt work
//   if (scrollInfoCount >= 1){
//     var now = 0;
//     console.log("ScrollInfoCount =", scrollInfoCount, ">= 1");
//     console.log("Now we should refresh");
//     console.log("now = ", now);
//     // onRefresh();
//     // let payload = {
//     //   msg: {
//     //     filter: filter,
//     //     ascend: ascend,
//     //     teamName: currTeam,
//     //   },
//     //   num: pageNum,
//     // };
//     // dispatch(fetchScrollInfoRefresh(payload));
//     // const timeoutId = setTimeout(onRefresh(), 15000);
//     // return () => {
//     //   clearTimeout(timeoutId);
//     // }
//   }
// }, [scrollInfoCount]);
// const [isLoaded, setIsLoaded] = useState(false);

//   useLayoutEffect(() => {
//     if (!isLoaded) {
//       setIsLoaded(true);
//       console.log('layout effect');
//       onRefresh();
//     }
//   }, [isLoaded]);
// useEffect(() => {
//   if (renderCount < 5) {
//     // Your function logic here
//     console.log("Function executed after rendering");
    
//     // Increment the render count
//     setRenderCount(prevCount => prevCount + 1);
//   }
// }, [renderCount]);
//setTimeout(console.log("timeout"), 10000);

// const [onRefreshStartupCount, setonRefreshStartupCount] = useState(0);
// const onRefreshStartup = async() => { //paired with onmouse move, 
//   setonRefreshStartupCount((prevOnRefreshStartupCount) => prevOnRefreshStartupCount + 1);
//   console.log("mouse move");
//   if (onRefreshStartupCount == 1){
//     console.log("Page Num: ", pageNum);
//     let payload = {
//       msg: {
//         filter: filter,
//         ascend: ascend,
//         teamName: currTeam,
//       },
//       num: pageNum,
//     };
//     let response = await dispatch(fetchScrollInfoRefresh(payload));
//     setTrapLength(response.payload.trapLength); //probably useless
    
//     console.log("Called onRefreshStartup, onRefreshStartupCount = ", onRefreshStartupCount);
//   }
  
// }

  return (
    <div className="profile-box">
      <div className="trap-section">
        <div className="trap-section-title">SmartProbes:</div>
        <div className="buttonContainer">
          <div className="buttonLeftContainer" >
            <Pagination 
              count={trapLength ? Math.ceil(trapLength / 20) : 0} 
              siblingCount={0}
              page={currentPage}
              onChange={handlePageChange}
            />
          </div>
          <div className="buttonRightContainer" >
            <input alt="filter button" type="image" src={filterIcon} className={btnDisable ? "profileDisable" : "filterBtn"} onClick={() => setOpenFilterOptions(true)} disabled={btnDisable} />
            <input alt="refresh button" type="image" src={refreshIcon} className={btnDisable ? "profileDisable" : "refreshBtn"} onClick={() => onRefresh()} disabled={btnDisable} />
          </div>
        </div>
        {openFilterOptions && <FilterOptionsModal open={openFilterOptions} setOpen={setOpenFilterOptions} handlePageNumState={handlePageNumState} 
          setCurrentPage={setCurrentPage} pageNum = {pageNum} currTeam = {currTeam} scrollInfo={scrollInfo} setFilterConfirmed={setFilterConfirmed}/>}
        <div className="trap-section-list">
          {loading ? (
            <>
              <div id="loading-wheel"><img src={loadingIcon} alt="Loading..."></img></div>
            </>
          ) : scrollInfo && scrollInfo.traps ? ( //only render this once refreshed twice
            scrollInfo.traps.map((num, idx) => (
              <div
                className={
                  parseInt(num.trapId) === trapId ? "trap-btn-active" : "trap-btn-nonactive"
                }
                id={num}
                key={idx}
                onClick={() => selectTrap(num.trapId)}
              > 
                <div className="trap-section-list-container" id={num}>
                  <div className="trap-section-list-left" id={num}>
                    <div className="trap-section-list-info-name" id={num}>
                      {num && num.trapName
                        ? num.trapName
                        : "ID: " + num.trapId}
                        {num.favorite ? <BsStarFill color="gold" size={20} style={{ marginLeft: "2px" }} /> : <></>} 
                    </div>
                    <div className="trap-section-list-info-location" id={num}>
                      {num && num.building
                        ? num.building
                        : "Building"}
                    </div>
                    <div className="trap-section-list-info-location" id={num}>
                      { num && num.location
                        ? num.location
                        : "Location"}
                    </div>
                  </div>
                  <div className="trap-section-list-right" id={num}>
                    <WifiIndicator
                      iconvalue={num.wifiInfo}
                    ></WifiIndicator>
                    <BatteryIndicator
                      count={num.batteryInfo}
                    ></BatteryIndicator>
                    <InsectIndicator
                      count={num.particleCount}
                    ></InsectIndicator>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <>
              <div>
                <h2>No Data</h2>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import "./Footer.sass";

import logo from "assets/icon.png";

export default function Footer() {
    return (
        <div className='footer'>
            <div className="imgContainer">
                <a href="/">
                    <img className="footerImg" src={logo}/>
                </a>
            </div>
            <div className='contact'>
                <h3 className="header">Contact Us</h3>
                <text className="txt">support@aivisionfood.com</text>
            </div>
        </div>
    )
}
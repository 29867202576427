import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Redirect,
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { confirmForgotPassword } from "redux/slices/authSlice";
import validator from "validator";
import "./skip-forgot-password.sass";

import PasswordErrorModal from "./PasswordErrorModal";
import CodeExpiredModal from "./CodeExpiredModal";

export default function SkipForgotPassword() {

    const navigate = useHistory();

    const dispatch = useDispatch();

    const [email, setEmail] = useState("");
    const [fakeSubmitting, setFakeSubmitting] = useState(false);

    const [countFail, setCountFail] = useState(1);
    const [errorModal, setErrorModal] = useState(false);
    const [expiredModal, setExpiredModal] = useState(false);

    const [code, setCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");

    // email validator
    const [validEmail, setValidEmail] = useState(true);
    useEffect(() => {
        if (email) {
            if (validator.isEmail(email) === true) {
                setValidEmail(true);
            } else {
                setValidEmail(false);
            }
        } else {
            setValidEmail(true);
        }
    }, [email]);

    // confirm new password matches with new password
    const [newPasswordEquals, setNewPasswordEquals] = useState(false);
    useEffect(() => {
        if (confirmNewPassword === newPassword) {
            setNewPasswordEquals(false);
        } else {
            setNewPasswordEquals(true);
        }
    }, [confirmNewPassword, newPassword]);

    // password format validation
    const [disPlayPassError, setDisplayPassError] = useState(false);
    useEffect(() => {
        if (newPassword) {
            let reg = /.{8,}$/;
        if (reg.test(newPassword) === false) {
            setDisplayPassError(true);
        } else {
            setDisplayPassError(false);
        }
        } else {
            setDisplayPassError(false);
        }
    }, [newPassword]);

    const changePassword = async () => {
        if (
          email &&
          code &&
          newPassword &&
          confirmNewPassword &&
          !disPlayPassError &&
          !newPasswordEquals
        ) {
            setFakeSubmitting(false);
    
          let user = {
            email: email,
            code: code,
            newpassword: confirmNewPassword,
          };
    
          let response = await dispatch(confirmForgotPassword(user));

          if (response.payload.response !== "SUCCESS") {
            setCountFail(countFail + 1);
            setCode("");
            if (countFail === 3) {
                setExpiredModal(true);
                return;
            } else {
                setErrorModal(true);
            }
        } else {
            navigate.push("/createNewPasswordSuccess");
        }
    
        } else {
          setFakeSubmitting(true);
        }
      };

    const goBack = () => {
        navigate.push("/forgotPassword");
    }

    return (
        <div className="skip-main-container">
            <div className="skip-top-form">
                <button className="skip-back-btn" onClick={() => goBack()}>Back</button>
            </div>
            <div className="skip-bottom-form">
                <h1 className="skip-title">Create new password</h1>
                <p className="skip-txt">Your new password must be different from previous used passwords.</p>
                <div className="skip-form-container">
                    <label className="skip-form-title">
                        Email Address
                    </label>
                    <input
                        type="email"
                        className="skip-input"
                        placeholder="Enter your email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {!email && fakeSubmitting && <p className="skip-error">Can't be blank</p>}
                    {!validEmail && <p className="skip-error">Invalid email</p>}
                </div>
                <div className="skip-form-container">
                    <label className="skip-form-title">
                        Verification Code
                    </label>
                    <input
                        type="number"
                        className="skip-input"
                        placeholder="Enter verification code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                    />
                    {!code && fakeSubmitting && <p className="skip-error">Can't be blank</p>}
                </div>
                <div className="skip-form-container">
                    <label className="skip-form-title">
                        New Password
                    </label>
                    <input
                        type="password"
                        className="skip-input"
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    {!newPassword && fakeSubmitting && <p className="skip-error">Can't be blank</p>}
                    {disPlayPassError && <p className="skip-error">Password must be at least 8 characters</p>}
                </div>
                <div className="skip-form-container">
                    <label className="skip-form-title">
                        Confirm New Password
                    </label>
                    <input
                        type="password"
                        className="skip-input"
                        placeholder="Re-type new password"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />
                    {!confirmNewPassword && fakeSubmitting && <p className="skip-error">Can't be blank</p>}
                    {newPasswordEquals && <p className="skip-error">Password Confirmation does not match</p>}
                </div>
                <div className="skip-btn-container">
                    <button className="skip-send-btn" onClick={() => changePassword()}>Reset Password</button>
                </div>
            </div>
            {errorModal && <PasswordErrorModal open={errorModal} setOpen={setErrorModal} />}
            {expiredModal && <CodeExpiredModal open={expiredModal} setOpen={setErrorModal} />}
        </div>
    );
};
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Redirect,
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { confirmForgotPassword } from "redux/slices/authSlice";
import "./create-new-password.sass";

import PasswordErrorModal from "./PasswordErrorModal";
import CodeExpiredModal from "./CodeExpiredModal";

export default function CreateNewPassword() {

    const dispatch = useDispatch();

    const navigate = useHistory();

    const location = useLocation();
    const email = location.state.email;
    console.log(email);

    const [fakeSubmitting, setFakeSubmitting] = useState(false);
    
    const [countFail, setCountFail] = useState(1);
    const [errorModal, setErrorModal] = useState(false);
    const [expiredModal, setExpiredModal] = useState(false);

    const [code, setCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");


    // confirm new password matches with new password
    const [newPasswordEquals, setNewPasswordEquals] = useState(false);
    useEffect(() => {
        if (confirmNewPassword === newPassword) {
            setNewPasswordEquals(false);
        } else {
            setNewPasswordEquals(true);
        }
    }, [confirmNewPassword, newPassword]);

    // password format validation
    const [disPlayPassError, setDisplayPassError] = useState(false);
    useEffect(() => {
        if (newPassword) {
            let reg = /.{8,}$/;
        if (reg.test(newPassword) === false) {
            setDisplayPassError(true);
        } else {
            setDisplayPassError(false);
        }
        } else {
            setDisplayPassError(false);
        }
    }, [newPassword]);

    const changePassword = async () => {
        if (
          code &&
          newPassword &&
          confirmNewPassword &&
          !disPlayPassError &&
          !newPasswordEquals
        ) {
            setFakeSubmitting(false);
    
            let user = {
                email: email,
                code: code,
                newpassword: confirmNewPassword,
            };
    
            let response = await dispatch(confirmForgotPassword(user));

            if (response.payload.response !== "SUCCESS") {
                setCountFail(countFail + 1);
                setCode("");
                if (countFail === 3) {
                    setExpiredModal(true);
                    return;
                } else {
                    setErrorModal(true);
                }
            } else {
                navigate.push("/createNewPasswordSuccess");
            }
        
            } else {
                setFakeSubmitting(true);
            }
        };

    const goBack = () => {
        navigate.push("/checkEmail", { email: email });
    }

    return (
        <div className="create-main-container">
            <div className="create-top-form">
                <button className="create-back-btn" onClick={() => goBack()}>Back</button>
            </div>
            <div className="create-bottom-form ">
                <h1>Create new password</h1>
                <p>Your new password must be different from previous used passwords.</p>
                <div className="create-form-container">
                    <label className="create-form-title">
                        Verification Code
                    </label>
                    <input
                        type="number"
                        className="create-input"
                        placeholder="Enter verification code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                    />
                    {!code && fakeSubmitting && <p className="create-error">Can't be blank</p>}
                </div>
                <div className="create-form-container">
                    <label className="create-form-title">
                        New Password
                    </label>
                    <input
                        type="password"
                        className="create-input"
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    {!newPassword && fakeSubmitting && <p className="create-error">Can't be blank</p>}
                    {disPlayPassError && <p className="create-error">Password must be at least 8 characters</p>}
                </div>
                <div className="create-form-container">
                    <label className="create-form-title">
                        Confirm New Password
                    </label>
                    <input
                        type="password"
                        className="create-input"
                        placeholder="Re-type new password"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />
                    {!confirmNewPassword && fakeSubmitting && <p className="create-error">Can't be blank</p>}
                    {newPasswordEquals && <p className="create-error">Password Confirmation does not match</p>}
                </div>
                <div className="create-btn-container">
                    <button className="create-send-btn" onClick={() => changePassword()}>Reset Password</button>
                </div>
            </div>
            {errorModal && <PasswordErrorModal open={errorModal} setOpen={setErrorModal} />}
            {expiredModal && <CodeExpiredModal open={expiredModal} setOpen={setErrorModal} />}
        </div>
    );
};